import instanceAxios from "src/utils/axios";
import {createAsyncThunk} from '@reduxjs/toolkit'
import {CreateAction} from "src/utils/types";
import {getActionList, getActionDetail} from "src/reducers/action.slice";

export const handleCreateNewAction = createAsyncThunk(
  'action/create-new-action',
  async (params: CreateAction, thunkAPI) => {
    try {
      const response = await instanceAxios.post(`/kpi-api/api/action`, params)
      return response.data.result
    } catch(err) {
      return  thunkAPI.rejectWithValue(err)
    }
  }
)

export const handleGetListAction = createAsyncThunk(
  'action/get-list',
  async (params: { goal: string }, thunkAPI) => {
    try {
      const response = await instanceAxios.get(`/kpi-api/api/action`, {params})
      thunkAPI.dispatch(getActionList(response.data))
      return response.data.result
    } catch(err) {
      return  thunkAPI.rejectWithValue(err)
    }
  }
)

export const handleDeleteAction = createAsyncThunk(
  'aim/delete-aim',
  async (params: string, thunkAPI) => {
    try {
      const response = await instanceAxios.delete(`/kpi-api/api/action/${params}`)
      return response.data.data
    } catch(err) {
      return  thunkAPI.rejectWithValue(err)
    }
  }
)

export const handleEditAction = createAsyncThunk(
  'action/edit-action',
  async (params: CreateAction, thunkAPI) => {
    const { id } = params;
    delete params.id;
    try {
      const response = await instanceAxios.put(`/kpi-api/api/action/${id}`, params)
      return response.data.data
    } catch(err) {
      return  thunkAPI.rejectWithValue(err)
    }
  }
)

export const handleGetActionDetail = createAsyncThunk(
  'action/detail-action',
  async (id: string, thunkAPI) => {
    try {
      const response = await instanceAxios.get(`/kpi-api/api/action/${id}`)
      thunkAPI.dispatch(getActionDetail(response.data))
      return response.data.data
    } catch(err) {
      return  thunkAPI.rejectWithValue(err)
    }
  }
)