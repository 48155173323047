export enum ChildrenAimStatusType {
  REGISTER_GOAL = 'register_goal',
  REJECTED_GOAL = 'rejected_goal',
  APPROVED_GOAL = 'approved_goal',
  SEND_RESULT = 'send_result',
  APPROVED_RESULT = 'approved_result',
  REJECTED_RESULT = 'rejected_result',
}

export enum AimLevelEnums {
  LEVEL_1 = 'LEVEL_1',
  LEVEL_2 = 'LEVEL_2',
  LEVEL_3 = 'LEVEL_3',
}

export enum SentResultActionType {
  NUMBER = 'number',
  STATUS = 'complete',
}

export enum PeriodStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  CLOSED = 'closed'
}

export enum LevelGoalEnum {
  LEVEL_1 = 1,
  LEVEL_2 = 2,
  LEVEL_3 = 3,
}

export enum StatusTrendEnum {
  UP = 'up',
  DOWN = 'down',
}

export enum UnitEnums {
  VND = 'VND',
  PERCENT = 'PERCENT',
  PROJECT = 'PROJECT',
  CUSTOMER = 'CUSTOMER',
  POINT = 'POINT',
  QUANTITY = 'QUANTITY',
  ERROR = 'ERROR',
  AMOUNT = 'AMOUNT',
  SURFACE = 'SURFACE',
  CONTRACT = 'CONTRACT',
  APARTMENT  = 'APARTMENT',
  DAY = 'DAY',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
  TIMES = 'TIMES',
  SHOP = 'SHOP',
  DOCUMENT = 'DOCUMENT',
  CLASSES = 'CLASSES',
  STUDENTS = 'STUDENTS',
  IN_HOUSE_TEACHER = 'IN_HOUSE_TEACHER'
}

export const UnitSelections = [
  {label: 'VND', value: UnitEnums.VND},
  {label: '%', value: UnitEnums.PERCENT},
  {label: 'Dự án', value: UnitEnums.PROJECT},
  {label: 'Khách hàng', value: UnitEnums.CUSTOMER},
  {label: 'Điểm', value: UnitEnums.POINT},
  {label: 'Số lượng', value: UnitEnums.QUANTITY},
  {label: 'Số lỗi', value: UnitEnums.ERROR},
  {label: 'Khoản', value: UnitEnums.AMOUNT},
  {label: 'Mặt bằng', value: UnitEnums.SURFACE},
  {label: 'Hợp đồng', value: UnitEnums.CONTRACT},
  {label: 'Bộ phận', value: UnitEnums.APARTMENT},
  {label: 'Số ngày', value: UnitEnums.DAY},
  {label: 'Số giờ', value: UnitEnums.HOUR},
  {label: 'Số phút', value: UnitEnums.MINUTE},
  {label: 'Số lần', value: UnitEnums.TIMES},
  {label: 'Cửa hàng', value: UnitEnums.SHOP},
  {label: 'Tài liệu', value: UnitEnums.DOCUMENT},
  {label: 'Số lớp học', value: UnitEnums.CLASSES},
  {label: 'Số học viên', value: UnitEnums.STUDENTS},
  {label: 'Giảng viên nội bộ', value: UnitEnums.IN_HOUSE_TEACHER},
]

export const TypeMeasureSelections = [
  {label: 'Dạng số', value: SentResultActionType.NUMBER},
  {label: 'Hoàn thành/ Chưa hoàn thành', value: SentResultActionType.STATUS},
]

export const StatusTrendSelections = [
  {label: 'Xu hướng tăng', value: StatusTrendEnum.UP},
  {label: 'Xu hướng giảm', value: StatusTrendEnum.DOWN},
]

// Phê duyệt/ từ chối
export enum StatusResultEnum {
  SEND_RESULT = 'send_result',
  APPROVED_RESULT = 'approved_result',
  REJECTED_RESULT = 'rejected_result',
}

export enum HavingChildrenEnum {
  NO_HAVING_CHILDREN = 2,
  HAVING_CHILDREN = 3
}

export enum SendResultTypeEnum {
  ACTION = 'ACTION',
  AIM = 'AIM'
}

export enum PermissionList {
  OWNER_PERMISSION_GOAL = 'Mục tiêu cá nhân',
  OWNER_PERMISSION_PHASE = 'Giai đoạn',
  APPROVED_LEADER_GOAL = 'Duyệt mục tiêu leader',
  APPROVED_EMPLOYEE_GOAL = 'Duyệt mục tiêu nhân viên',
  APPROVED_OWNER_GOAL = 'Duyệt mục tiêu bản thân',
  APPROVED_LEADER_RESULT = 'Xác nhận kết quả của leader (làm sau)',
  APPROVED_EMPLOYEE_RESULT = 'Xác nhận kết quả của nhân viên (làm sau)',
  APPROVED_OWNER_RESULT = 'Xác nhận kết quả cá nhân',
  READ_EMPLOYEE_GOAL = 'Xem mục tiêu của các cấp dưới',
EXPORT_REPORT_GOAL = 'Xuất file excel',
}

export enum PermissionListEnum {
  OWNER_PERMISSION_GOAL = 'OWNER_PERMISSION_GOAL',
  OWNER_PERMISSION_PHASE = 'OWNER_PERMISSION_PHASE',
  APPROVED_LEADER_GOAL = 'APPROVED_LEADER_GOAL',
  APPROVED_EMPLOYEE_GOAL = 'APPROVED_EMPLOYEE_GOAL',
  APPROVED_OWNER_GOAL = 'APPROVED_OWNER_GOAL',
  APPROVED_LEADER_RESULT = 'APPROVED_LEADER_RESULT',
  APPROVED_EMPLOYEE_RESULT = 'APPROVED_EMPLOYEE_RESULT',
  APPROVED_OWNER_RESULT = 'APPROVED_OWNER_RESULT',
  READ_EMPLOYEE_GOAL = 'READ_EMPLOYEE_GOAL',
EXPORT_REPORT_GOAL = 'EXPORT_REPORT_GOAL',
}

export enum EmployeeTypeEnum {
  LEADER = 'leader',
  EMPLOYEE = 'employee',
  ALL = 'all'
}

export enum ManageTypes {
  MANAGED_BY_ME = 'MANAGED_BY_ME',
  OWNER = 'OWNER',
  REPORT = 'REPORT'
}

export enum ManageTypesRoutes {
  MANAGED_BY_ME = 'managed-by-me',
  OWNER = 'aims',
  REPORT = 'report'
}

export const LeaderFilter = [
  {label: 'Leader', value: EmployeeTypeEnum.LEADER},
  {label: 'Nhân viên', value: EmployeeTypeEnum.EMPLOYEE},
  {label: 'Tất cả', value: EmployeeTypeEnum.ALL},
]

export const StatusGoalFilter = [
  {label: 'Chờ duyệt', value: ChildrenAimStatusType.REGISTER_GOAL},
  {label: 'Đã duyệt', value: ChildrenAimStatusType.APPROVED_GOAL},
  {label: 'Không duyệt', value: ChildrenAimStatusType.REJECTED_GOAL},
]
