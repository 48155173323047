import {Space, Tag, Typography, Skeleton, Progress} from "antd";
import Button from "src/components/Button";
import {EditV2} from "src/assets/icons";
import React, {useMemo, useState} from "react";
import {AimLevelEnums, ChildrenAimStatusType, LevelGoalEnum} from "src/utils/enums";
import {CreateAimResponseType} from "src/utils/types";
import dayjs from "dayjs";
import {handleGetAimDetail} from "src/services/aims.services";
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import {useParams} from "react-router";
import {handleChildrenAimState, handleCompletedPercent, handleDisplayActionButton} from "src/utils/functions";
import CreateOrEditAimLevel3 from "src/components/Form/CreateOrEditAimLevel3";
import CreateOrEditAimLevel2 from "src/components/Form/CreateOrEditAimLevel2";
import CreateOrEditAimLevel1 from "src/components/Form/CreateOrEditAimLevel1";
import Modal from "src/components/Modal";
import './styles.scss';
import useRole from "src/hooks/useRole";
import MessageReject from "src/pages/AimDetail/MessageReject";
import CollapseInformation from "src/pages/AimDetail/CollapseInformation";

interface Props {
  level?: AimLevelEnums
  aimDetail?: CreateAimResponseType
  loading?: boolean
}

const AimInformationItem = ({title, description}: { title: string, description: string }) => {
  return (
    <div>
      <span style={{fontWeight: 700, width: 'max-content'}}>{title}: </span>
      <span>{description}</span>
    </div>
  )
}

const ShortDetail = (props: Props) => {
  const {level, aimDetail, loading} = props;
  const { aimDetail: aimLevel1Detail} = useAppSelector((state) => state.aim);
  const {isActionWithOwnerGoal} = useRole();

  const percent = handleCompletedPercent(aimDetail)?.percent.toFixed(2);

  const dispatch = useAppDispatch();
  const {aimIdLevel1, aimIdLevel2} = useParams();

  const [openEditAimLevel1, setOpenEditAimLevel1] = useState(false);
  const [openEditAimLevel2, setOpenEditAimLevel2] = useState(false);
  const [openEditAimLevel3, setOpenEditAimLevel3] = useState(false);
  const [openWatchRejectMessage, setOpenWatchRejectMessage] = useState(false);

  const {isDisplayEditButton} = handleDisplayActionButton(aimLevel1Detail);

  const status = useMemo(() => {
    if (!aimDetail) return;
    return handleChildrenAimState(aimDetail?.statusGoal as ChildrenAimStatusType, aimDetail?.statusResult as ChildrenAimStatusType);
  }, [aimDetail])

  const handleEditPopup = () => {
    switch (level) {
      case AimLevelEnums.LEVEL_1:
        setOpenEditAimLevel1(true);
        break;
      case AimLevelEnums.LEVEL_2:
        setOpenEditAimLevel2(true);
        break;
      case AimLevelEnums.LEVEL_3:
        setOpenEditAimLevel3(true);
        break;
    }
  }

  return (
    <>
      {
        loading ? (
          <Skeleton paragraph={{ rows: 0, width: 100, style: { margin: 0 } }}/>
        ) : (
          <Space direction="horizontal" style={{width: '100%'}} styles={{item: {flex: 1}}}>
            <Space style={{width: '100%'}}>
              <Typography style={{fontWeight: 700, fontSize: '16px', lineHeight: '24px', color: '#525252'}}>Thông tin mục tiêu</Typography>
              {
                aimDetail?.levelGoal === LevelGoalEnum.LEVEL_1 && (
                  <>
                    <Tag
                      style={{
                        color: status?.textColor,
                        backgroundColor: status?.bgColor,
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '20px',
                        padding: '2px 12px',
                        border: 'none',
                        margin: 0
                      }}
                    >
                      {status?.value}
                    </Tag>
                    {
                      aimDetail?.statusGoal === ChildrenAimStatusType.REJECTED_GOAL && (
                        <Button
                          onClick={() => setOpenWatchRejectMessage(true)}
                          style={{padding: '2px 12px'}}
                        >
                          <Typography.Text>Xem lý do</Typography.Text>
                        </Button>
                      )
                    }
                  </>
                )
              }

              {
                (isActionWithOwnerGoal && isDisplayEditButton) && (
                  <Button
                    onClick={handleEditPopup}
                    style={{display: 'flex', alignItems: 'center', padding: '3px 12px'}}
                  >
                    <EditV2/>
                    <Typography.Text style={{marginLeft: '8px', fontSize: '12px'}}>Chỉnh sửa</Typography.Text>
                  </Button>
                )
              }
            </Space>

            <Space style={{ width: '100%' }}>
              <Typography style={{fontWeight: 700, fontSize: '16px', lineHeight: '24px', color: '#525252'}}>Tỷ lệ hoàn thành: <span style={{ color: '#00B578' }}>{percent}%</span></Typography>
            </Space>
          </Space>
        )
      }

      {/*---------------------------- Chỉnh sửa mục tiêu cấp 1 -----------------------------------*/}
      <CreateOrEditAimLevel1
        open={openEditAimLevel1}
        selectedItem={aimDetail}
        closeCb={() => {
          setOpenEditAimLevel1(false)
        }}
        successCb={() => {
          dispatch(handleGetAimDetail({id: aimDetail?.id as string, level: AimLevelEnums.LEVEL_1}))
          setOpenEditAimLevel1(false)
        }}
      />

      {/*---------------------------- Chỉnh sửa mục tiêu cấp 2 -----------------------------------*/}
      <CreateOrEditAimLevel2
        open={openEditAimLevel2}
        selectedItem={aimDetail}
        parentId={aimIdLevel1}
        closeCb={() => {
          setOpenEditAimLevel2(false)
        }}
        successCb={() => {
          dispatch(handleGetAimDetail({id: aimDetail?.id as string, level: AimLevelEnums.LEVEL_2}))
          setOpenEditAimLevel2(false)
        }}
      />

      {/*---------------------------- Chỉnh sửa mục tiêu cấp 3 -----------------------------------*/}
      <CreateOrEditAimLevel3
        open={openEditAimLevel3}
        selectedItem={aimDetail?.id as string}
        parentId={aimIdLevel2}
        closeCb={() => {
          setOpenEditAimLevel3(false)
        }}
        successCb={() => {
          dispatch(handleGetAimDetail({id: aimDetail?.id as string, level: AimLevelEnums.LEVEL_3}))
          setOpenEditAimLevel3(false)
        }}
      />

      {/*----------------- Yêu cầu duyệt lại ---------------------*/}
      <Modal
        open={openWatchRejectMessage}
        modalType='none'
        title="Lý do từ chối"
        onCancel={() => {
          setOpenWatchRejectMessage(false)
        }}
      >
        <MessageReject data={aimDetail?.messagesReason || []}/>
      </Modal>
    </>
  )
}
const Detail = (props: Props) => {
  const {aimDetail} = props;

  const percent = handleCompletedPercent(aimDetail)?.percent.toFixed(2);

  const aim = useAppSelector((state) => state.aim);

  return (
    <Space className="aim-information" direction="vertical" style={{width: '100%'}}>
      <Space direction="horizontal" style={{width: '100%'}} styles={{item: {flex: 1}}}>
        <Space style={{padding: 16, width: '100%', borderRight: '1px solid #eee'}} direction="vertical">
          <AimInformationItem title="Tên mục tiêu" description={aimDetail?.name || '-'}/>
          <AimInformationItem title="Giai đoạn" description={aim?.aimDetail?.phase?.name || '-'}/>
          <AimInformationItem title="Người tạo" description={aimDetail?.created_by?.name || '-'}/>
          <AimInformationItem title="Ngày tạo" description={dayjs(aimDetail?.created_at).format('DD/MM/YYYY')}/>
          {/*<AimInformationItem title="Thời hạn hoàn thành mục tiêu"*/}
          {/*                    description={dayjs(aimDetail?.toDate).format('DD/MM/YYYY')}/>*/}
          {/*<AimInformationItem title="Mục tiêu tham chiếu"*/}
          {/*                    description={aimDetail?.reference?.name || 'Không có mục tiêu tham chiếu'}/>*/}
        </Space>
        <Space direction="vertical" style={{ width: '100%', textAlign: 'center' }}>
          <Progress
            style={{fontWeight: 700, fontSize: '22px', lineHeight: '28px'}}
            format={() => `${percent}%`}
            strokeLinecap="butt" type="circle" percent={Number(percent) || 0}
            strokeColor={{'0%': '#059669', '100%': '#84CC16'}}/>
        </Space>
      </Space>
    </Space>
  )
}

const AimInformation = (props: Props) => {
  const {level, aimDetail, loading} = props;
  return (
    <CollapseInformation
      shortDetail={
        <ShortDetail level={level} aimDetail={aimDetail} loading={loading}/>
      }
      detail={
        <Detail level={level} aimDetail={aimDetail} loading={loading}/>
      }
    />
  )
}

export default AimInformation
