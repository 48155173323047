import instanceAxios from "src/utils/axios";
import {createAsyncThunk} from '@reduxjs/toolkit'
import {CreateAimType} from "src/utils/types";
import {
  finishLoading,
  getAimDetail,
  getAimLevel2Detail,
  getAimLevel3Detail,
  getAimList,
  getMyAimList,
  startLoading,
  getReferenceAimList,
  getReportList
} from "src/reducers/aim.slice";
import {AimLevelEnums, ChildrenAimStatusType} from "src/utils/enums";
import {FilterParams} from "src/hooks/useFilter";

export const handleCreateNewAim = createAsyncThunk(
  'aim/create-new-aim',
  async (params: CreateAimType, thunkAPI) => {
    try {
      const response = await instanceAxios.post(`/kpi-api/api/goal`, params)
      return response.data.data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const handleGetAimList = createAsyncThunk(
  'aim/get-list',
  async (params: FilterParams | undefined, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading())
      const response = await instanceAxios.get(`/kpi-api/api/goal`, {params})
      thunkAPI.dispatch(getAimList(response.data))
      thunkAPI.dispatch(finishLoading())
      return response.data.data
    } catch (err) {
      thunkAPI.dispatch(finishLoading())
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const handleGetReportList = createAsyncThunk(
  'aim/get-list',
  async (params: FilterParams | undefined, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading())
      const response = await instanceAxios.get(`/kpi-api/api/report/goal`, {params})
      thunkAPI.dispatch(getReportList(response.data))
      thunkAPI.dispatch(finishLoading())
      return response.data.data
    } catch (err) {
      thunkAPI.dispatch(finishLoading())
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const handleGetRefAimList = createAsyncThunk(
  'aim/get-list-ref',
  async (params: FilterParams | undefined, thunkAPI) => {
    try {
      const response = await instanceAxios.get(`/kpi-api/api/goal/reference-goal`, {params})
      thunkAPI.dispatch(getReferenceAimList(response.data))
      return response.data.data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const handleGetMyAimList = createAsyncThunk(
  'aim/get-list',
  async (params: FilterParams, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading())
      const response = await instanceAxios.get(`/kpi-api/api/goal/my-goal`, {params})
      thunkAPI.dispatch(getMyAimList(response.data))
      thunkAPI.dispatch(finishLoading())
      return response.data.data
    } catch (err) {
      thunkAPI.dispatch(finishLoading())
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const handleDeleteAim = createAsyncThunk(
  'aim/delete-aim',
  async (params: string, thunkAPI) => {
    try {
      const response = await instanceAxios.delete(`/kpi-api/api/goal/${params}`)
      return response.data.data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const handleGetAimDetail = createAsyncThunk(
  'aim/get-detail',
  async (params: { id: string, level: AimLevelEnums }, thunkAPI) => {
    try {
      const response = await instanceAxios.get(`/kpi-api/api/goal/${params.id}`)
      if (params.level === AimLevelEnums.LEVEL_1) {
        thunkAPI.dispatch(getAimDetail(response.data))
      }
      if (params.level === AimLevelEnums.LEVEL_2) {
        thunkAPI.dispatch(getAimLevel2Detail(response.data))
      }
      if (params.level === AimLevelEnums.LEVEL_3) {
        thunkAPI.dispatch(getAimLevel3Detail(response.data))
      }
      return response.data.data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const handleEditAim = createAsyncThunk(
  'aim/edit-aim',
  async (params: CreateAimType, thunkAPI) => {
    const {id} = params;
    delete params.id;
    try {
      const response = await instanceAxios.put(`/kpi-api/api/goal/${id}`, params);
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const handleAimState = createAsyncThunk(
  'aim/approved-reject',
  async (params: {
    id: string,
    statusGoal?: ChildrenAimStatusType,
    messageReason?: string,
    isApprovedAllGoal?: string | null
  }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading())
      const {id} = params;
      const response = await instanceAxios.put(`/kpi-api/api/goal/approve-goal/${id}`, {
        statusGoal: params.statusGoal,
        messageReason: params?.messageReason,
        isApprovedAllGoal: params?.isApprovedAllGoal
      })
      thunkAPI.dispatch(finishLoading())
      return response.data.data
    } catch (err) {
      thunkAPI.dispatch(finishLoading())
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const handleApprovedResult = createAsyncThunk(
  'result/approved-result',
  async (params: { id: string, statusResult: ChildrenAimStatusType }, thunkAPI) => {
    try {
      const response = await instanceAxios.put(`/kpi-api/api/goal/approve-result/${params.id}`, {statusResult: params.statusResult})
      return response.data.data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
);

export const handleExportExcel = createAsyncThunk(
  'aim/get-list',
  async (params: FilterParams & { excel_export: boolean }, thunkAPI) => {
    try {
      const response = await instanceAxios.get(`/kpi-api/api/report/goal`, {params})
      return response.data.data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)
