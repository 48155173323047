import Modal from "src/components/Modal";
import {SendResultTypeEnum, SentResultActionType} from "src/utils/enums";
import {FormProvider, useForm} from "react-hook-form";
import React from "react";
import Form from './Form'
import {handleEditAim} from "src/services/aims.services";
import {CreateAction, CreateAimResponseType, CreateAimType} from "src/utils/types";
import {useAppDispatch} from "src/redux/hooks";
import {handleEditAction} from "src/services/actions.services";
import {toast} from "react-toastify";

interface Props {
  open: boolean;
  detail: CreateAimResponseType | undefined;
  successCb: () => void;
  errorCb: () => void;
  sendResultType: SendResultTypeEnum
}

const defaultValuesResult = {
  resultComplete: 1,
  result: 0
}

const SendResult = ({ open, detail, successCb, errorCb, sendResultType}: Props) => {
  const dispatch = useAppDispatch();

  const methodsResult = useForm({
    defaultValues: defaultValuesResult
  });

  const sentResult = (dt: any) => {
    if(!detail) return;
    let formData;
    const result = Number(dt?.result);
    const resultComplete = dt?.resultComplete;

    if (detail.typeMeasure === SentResultActionType.NUMBER) {
      formData = {
        id: detail.id,
        result
      }
    }

    if (detail.typeMeasure === SentResultActionType.STATUS) {
      formData = {
        id: detail.id,
        result: resultComplete
      }
    }

    if (sendResultType === SendResultTypeEnum.AIM) {
      dispatch(handleEditAim(formData as CreateAimType))
        .unwrap()
        .then(() => {
          methodsResult.reset(defaultValuesResult)
          successCb();
          toast.success("Nộp kết quả hành động thành công", {
            position: toast.POSITION.BOTTOM_RIGHT
          });
        })
        .catch((err) => {
          methodsResult.reset(defaultValuesResult)
          const message = err?.response?.detail?.message;
          errorCb();
          toast.error(message || 'Đã có lỗi xảy ra. Vui lòng thử lại sau', {
            position: toast.POSITION.BOTTOM_RIGHT
          });
        })
    }

    if (sendResultType === SendResultTypeEnum.ACTION) {
      dispatch(handleEditAction(formData as CreateAction))
        .unwrap()
        .then(() => {
          methodsResult.reset(defaultValuesResult);
          successCb();
          toast.success("Nộp kết quả hành động thành công", {
            position: toast.POSITION.BOTTOM_RIGHT
          });
        })
        .catch((err) => {
          const message = err?.response?.data?.message;
          toast.error(message || 'Đã có lỗi xảy ra. Vui lòng thử lại sau', {
            position: toast.POSITION.BOTTOM_RIGHT
          });
          methodsResult.reset(defaultValuesResult);
          errorCb();
        })
    }
  }

  return (
    <FormProvider {...methodsResult}>
      <form onSubmit={methodsResult.handleSubmit(sentResult)}>
        <Modal
          open={open}
          modalType='form'
          title="Nộp kết quả mục tiêu"
          okText="Cập nhật"
          cancelText="Hủy"
          onCancel={() => {
            methodsResult.reset(defaultValuesResult)
            errorCb()
          }}
          style={{
            minWidth: '800px'
          }}
        >
          <Form resultType={detail?.typeMeasure as SentResultActionType}/>
        </Modal>
      </form>
    </FormProvider>
  )
}

export default SendResult