import DashboardLayout from "src/layout/DashboardLayout";
import {ArrowBackV2} from "src/assets/icons";
import {Space, Typography} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import AimInformation from '../AimInformation'
import AimListLevel3 from "src/pages/AimDetail/AimList/AimListLevel3";
import Button from "src/components/Button";
import {AimLevelEnums, ChildrenAimStatusType, SendResultTypeEnum} from "src/utils/enums";
import {useNavigate, useParams} from "react-router";
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import {handleGetAimDetail} from "src/services/aims.services";
import {handleGetListAction} from "src/services/actions.services";
import SendResult from "src/components/Form/ActionLevel3/SendResult";

const AimDetailLevel3 = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { aimIdLevel1, aimIdLevel3 } = useParams();
  const {actionList} = useAppSelector((state) => state.action);
  const {aimDetail, aimLevel3Detail} = useAppSelector((state) => state.aim);
  const [openSentResultAction, setOpenSentResultAction] = useState(false);
  const [loading, setLoading] = useState(false)

  const isSendOrApprovedResultLevel1 = useMemo(() => {
    if(!aimDetail) return;
    return aimDetail.statusResult === ChildrenAimStatusType.APPROVED_RESULT ||
      aimDetail.statusResult === ChildrenAimStatusType.SEND_RESULT;
  }, [aimDetail]);

  useEffect(() => {
    setLoading(true)
    aimIdLevel3 && dispatch(handleGetAimDetail({ id: aimIdLevel3, level: AimLevelEnums.LEVEL_3 }))
      .unwrap()
      .then(() => setLoading(false))
      .catch(() => setLoading(false))

    aimIdLevel1 && dispatch(handleGetAimDetail({ id: aimIdLevel1 as string, level: AimLevelEnums.LEVEL_1 }));
    aimIdLevel3 && dispatch(handleGetListAction({ goal: aimIdLevel3 as string }));
  }, [aimIdLevel1, aimIdLevel3]);

  return (
    <DashboardLayout
      header={
        <Space style={{gap: '24px'}}>
          <ArrowBackV2 style={{cursor: 'pointer'}} onClick={() => navigate(-1)}/>
          <div>
            <Typography className="dashboard-title">Chi tiết mục tiêu cấp 3</Typography>
            <Typography
              style={{
                color: '#737373',
                fontSize: '12px'
              }}
            >
              Danh sách mục tiêu/ {aimDetail?.name || '-'}/ {aimLevel3Detail?.parent?.name || '-'}/ <span style={{color: '#525252', fontWeight: 600}}>{aimLevel3Detail?.name || '-'}</span>
            </Typography>
          </div>
        </Space>
      }
      actionButtons={[
        !isSendOrApprovedResultLevel1 && (
          <Button
            onClick={() => setOpenSentResultAction(true)}
            type="primary"
            key='sent-result'
          >
            Nộp kết quả
          </Button>
        )
      ]}
    >
      <AimInformation level={AimLevelEnums.LEVEL_3} aimDetail={aimLevel3Detail} loading={loading}/>
      <AimListLevel3
        parentId={aimLevel3Detail?.id}
        childAimList={actionList}
        aimDetail={aimLevel3Detail}
      />

      {/*---------------------------- Nộp kết quả mục tiêu cấp 3 -----------------------------------*/}
      <SendResult
        sendResultType={SendResultTypeEnum.AIM}
        open={openSentResultAction}
        detail={aimLevel3Detail}
        successCb={() => {
          dispatch(handleGetAimDetail({ id: aimIdLevel3 as string, level: AimLevelEnums.LEVEL_3 }));
          setOpenSentResultAction(false)
        }}
        errorCb={() => {
          setOpenSentResultAction(false)
        }}
      />
    </DashboardLayout>
  )
}

export default AimDetailLevel3;
