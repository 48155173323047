import DashboardLayout from "src/layout/DashboardLayout";
import {ArrowBackV2} from "src/assets/icons";
import {Space, Typography} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import AimInformation from "src/pages/AimDetail/AimInformation";
import {AimLevelEnums, ChildrenAimStatusType, ManageTypes, ManageTypesRoutes} from "src/utils/enums";
import {useNavigate, useParams} from "react-router";
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import {handleAimState, handleApprovedResult, handleGetAimDetail} from "src/services/aims.services";
import AimListLevel1 from "src/pages/AimDetail/AimList/AimListLevel1";
import Button from "src/components/Button";
import useRole from "src/hooks/useRole";
import Modal from "src/components/Modal";
import Input from "src/components/Input";
import {toast} from "react-toastify";
import ErrorMessage from "src/components/ErrorMessage";
import {Controller, useForm} from "react-hook-form";

const ManagedByMeDetail = () => {
  const {
    isApprovedEmployeeGoal,
    isApprovedOwnerGoal,
    isWatchAndApprovedLeaderGoal,
    isApprovedLeaderResult, // Xác nhận kết quả của leader
    isApprovedEmployeeResult,  // Xác nhận kết quả của nhân viên
    isApprovedOwnerResult // Xác nhận kết quả của bản thân
  } = useRole();

  const navigate = useNavigate();
  const {id} = useParams();
  const dispatch = useAppDispatch();
  const {aimDetail} = useAppSelector((state) => state.aim);
  const {userInfo} = useAppSelector((state) => state.user);

  const [loading, setLoading] = useState(true)

  const [openApproved, setOpenApproved] = useState(false);
  const [openRejected, setOpenRejected] = useState(false);
  const [openUndo, setOpenUndo] = useState(false);

  // Duyệt kết quả
  const [openApprovedResult, setOpenApprovedResult] = useState(false);
  const [openRejectedResult, setOpenRejectedResult] = useState(false);

  const {handleSubmit, control} = useForm({
    defaultValues: {
      messageReason: ''
    }
  })

  // kiểm tra mục tiêu cấp 1 đã được duyệt hay ko duyệt chưa
  const isApprovedOrRejectedGoal = useMemo(() => {
    if (!aimDetail) return;
    return aimDetail?.statusGoal !== ChildrenAimStatusType.REGISTER_GOAL
  }, [aimDetail]);

  // kiểm tra mục tiêu cấp 1 đã được gửi kết quả chưa
  const isSentResultGoal = useMemo(() => {
    if (!aimDetail) return;
    return aimDetail?.statusGoal === ChildrenAimStatusType.APPROVED_GOAL && aimDetail.statusResult === ChildrenAimStatusType.SEND_RESULT
  }, [aimDetail]);

  // kiểm tra mục tiêu cấp 1 có phải là mục tiêu của leader hay ko
  const isGoalLeader = useMemo(() => {
    if (!aimDetail) return;
    return aimDetail?.isGoalLeader
  }, [aimDetail]);

  // kiểm tra xem đó có phải muc tiêu của mình không
  const isMyGoal = useMemo(() => {
    if (!aimDetail || !userInfo) return;
    return aimDetail?.user?.id === userInfo?.id
  }, [aimDetail, userInfo]);

  // check hiển thị nút duyệt / không duyệt
  const isDisplayApprovedRejectButton = useMemo(() => {
    if (loading) return;
    return (!isMyGoal && isApprovedEmployeeGoal && !isApprovedOrRejectedGoal) || // nếu có quyền duyệt mục tiêu nhân viên
      (isMyGoal && isApprovedOwnerGoal && !isApprovedOrRejectedGoal) || // nếu có quyền duyệt muc tiêu cá nhân
      (!isMyGoal && isWatchAndApprovedLeaderGoal && !isApprovedOrRejectedGoal && isGoalLeader) // nếu có quyền duyệt mục tiêu leader
  }, [isGoalLeader, isApprovedEmployeeGoal, isApprovedOrRejectedGoal, isApprovedOwnerGoal, isMyGoal, isWatchAndApprovedLeaderGoal, loading]);

  // check hiển thị nút hoàn duyệt
  const isDisplayUndoButton = useMemo(() => {
    if (loading) return;
    return ((isApprovedEmployeeGoal && aimDetail?.isApprovedAllGoal) ||
      (isMyGoal && isApprovedOwnerGoal && aimDetail?.isApprovedAllGoal) ||
      (isGoalLeader && isWatchAndApprovedLeaderGoal && aimDetail?.isApprovedAllGoal))
  }, [loading, isApprovedEmployeeGoal, aimDetail?.isApprovedAllGoal, isMyGoal, isApprovedOwnerGoal, isGoalLeader, isWatchAndApprovedLeaderGoal]);

  // check hiển thị nút duyệt & từ chối kết quả
  const isDisplayApprovedResultButton = useMemo(() => {
    if (loading) return;
    return (!isMyGoal && isApprovedEmployeeResult && isSentResultGoal && !isGoalLeader) || // nếu có quyền duyệt kết quả nhân viên
      (isMyGoal && isApprovedOwnerResult && isSentResultGoal) || // nếu có quyền duyệt kết quả cá nhân
      (!isMyGoal && isApprovedLeaderResult && isSentResultGoal && isGoalLeader) // nếu có quyền duyệt kết quả leader
  }, [isApprovedEmployeeResult, isApprovedLeaderResult, isApprovedOwnerResult, isGoalLeader, isMyGoal, isSentResultGoal, loading]);

  useEffect(() => {
    if (!id) return;
    dispatch(handleGetAimDetail({id, level: AimLevelEnums.LEVEL_1}))
      .unwrap()
      .then(() => setLoading(false))
      .catch(() => {
        setLoading(false);
        toast.error('Đã có lỗi xảy ra. Vui lòng thử lại sau', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
  }, [id]);

  const handleAdminActions = (statusGoal: ChildrenAimStatusType, message: string) => {
    if (!id) return;
    dispatch(handleAimState({
      id: aimDetail?.id as string,
      ...(
        statusGoal === ChildrenAimStatusType.APPROVED_GOAL && {
          isApprovedAllGoal: "true",
          statusGoal
        }
      ),
      ...(
        statusGoal === ChildrenAimStatusType.REGISTER_GOAL && {
          isApprovedAllGoal: "false",
          statusGoal
        }
      )
    }))
      .unwrap()
      .then(() => {
        if (statusGoal === ChildrenAimStatusType.APPROVED_GOAL) {
          setOpenApproved(false);
        }

        if (statusGoal === ChildrenAimStatusType.REGISTER_GOAL) {
          setOpenUndo(false);
        }

        dispatch(handleGetAimDetail({id, level: AimLevelEnums.LEVEL_1}))
        toast.success(message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch(err => {
        const message = err?.response?.data?.message;
        toast.error(message || 'Đã có lỗi xảy ra. Vui lòng thử lại sau', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        if (statusGoal === ChildrenAimStatusType.APPROVED_GOAL) {
          setOpenApproved(false);
        }
      })
  }

  const handleApprovedRejectedGoalResult = (statusResult: ChildrenAimStatusType, message: string) => {
    if (!id) return;
    dispatch(handleApprovedResult({
      id: aimDetail?.id as string,
      statusResult
    }))
      .unwrap()
      .then(() => {
        if (statusResult === ChildrenAimStatusType.APPROVED_RESULT) {
          setOpenApprovedResult(false);
        }

        if (statusResult === ChildrenAimStatusType.REJECTED_RESULT) {
          setOpenRejectedResult(false);
        }

        dispatch(handleGetAimDetail({id, level: AimLevelEnums.LEVEL_1}))
        toast.success(message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch(err => {
        const message = err?.response?.data?.message;
        toast.error(message || 'Đã có lỗi xảy ra. Vui lòng thử lại sau', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        if (statusResult === ChildrenAimStatusType.APPROVED_RESULT) {
          setOpenApprovedResult(false);
        }

        if (statusResult === ChildrenAimStatusType.REJECTED_RESULT) {
          setOpenRejectedResult(false);
        }
      })
  }

  const rejectAim = (data: { messageReason: string }) => {
    if (!id) return;
    dispatch(handleAimState({
      id: aimDetail?.id as string,
      statusGoal: ChildrenAimStatusType.REJECTED_GOAL,
      messageReason: data.messageReason,
      isApprovedAllGoal: "false"
    }))
      .unwrap()
      .then(() => {
        setOpenRejected(false);
        dispatch(handleGetAimDetail({id: id as string, level: AimLevelEnums.LEVEL_1}))
        toast.success('Từ chối mục tiêu thành công', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch(err => {
        setOpenRejected(false);
        const message = err?.response?.data?.message;
        toast.error(message || 'Đã có lỗi xảy ra. Vui lòng thử lại sau', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
  }

  return (
    <DashboardLayout
      header={
        <Space style={{gap: '24px'}}>
          <ArrowBackV2 style={{cursor: 'pointer'}} onClick={() => navigate('/managed-by-me')}/>
          <div>
            <Typography className="dashboard-title">Chi tiết mục tiêu</Typography>
            <Typography
              style={{
                color: '#737373',
                fontSize: '12px'
              }}
            >
              Mục tiêu/ <span style={{color: '#525252', fontWeight: 600}}>{aimDetail?.name}</span>
            </Typography>
          </div>
        </Space>
      }
      actionButtons={[
        (isDisplayApprovedRejectButton && !aimDetail?.statusResult && <Button
          key='approved'
          style={{
            color: '#fff',
            backgroundColor: '#00B578',
          }}
          onClick={() => setOpenApproved(true)}
        >
          Duyệt
        </Button>),
        (isDisplayApprovedRejectButton && !aimDetail?.statusResult && <Button
          key='rejected'
          style={{
            backgroundColor: '#FF3141',
            color: '#fff'
          }}
          onClick={() => setOpenRejected(true)}
        >
          Không duyệt
        </Button>),
        (isDisplayUndoButton && !aimDetail?.statusResult && <Button
          type="primary"
          key='undo'
          onClick={() => setOpenUndo(true)}
        >
          Hoàn duyệt
        </Button>),
        (isDisplayApprovedResultButton && <Button
          key='approved'
          style={{
            color: '#fff',
            backgroundColor: '#00B578',
          }}
          onClick={() => setOpenApprovedResult(true)}
        >
          Duyệt kết quả
        </Button>),
        (isDisplayApprovedResultButton && <Button
          key='rejected'
          style={{
            backgroundColor: '#FF3141',
            color: '#fff'
          }}
          onClick={() => setOpenRejectedResult(true)}
        >
          Từ chối kết quả
        </Button>)
      ]}
    >
      <AimInformation level={AimLevelEnums.LEVEL_1} aimDetail={aimDetail} loading={loading}/>
      <AimListLevel1
        parentId={aimDetail?.id}
        childAimList={aimDetail?.childrens || []}
        loading={loading}
        manaType={ManageTypes.MANAGED_BY_ME}
        manaTypeRoute={ManageTypesRoutes.MANAGED_BY_ME}
      />

      {/*----------------- ADMIN DUYỆT MỤC TIÊU ---------------------*/}

      <Modal
        open={openApproved}
        modalType='confirm'
        title="Duyệt mục tiêu"
        okText="Đồng ý"
        cancelText="Hủy"
        onOk={() => handleAdminActions(ChildrenAimStatusType.APPROVED_GOAL, 'Duyệt mục tiêu thành công')}
        onCancel={() => setOpenApproved(false)}
      >
        <Typography
          style={{
            textAlign: 'center'
          }}
        >
          Xác nhận duyệt mục tiêu này ?
        </Typography>
      </Modal>

      {/*----------------- ADMIN TỪ CHỐI MỤC TIÊU ---------------------*/}
      <form onSubmit={handleSubmit(rejectAim)}>
        <Modal
          open={openRejected}
          title="Từ chối mục tiêu"
          okText="Đồng ý"
          cancelText="Hủy"
          onCancel={() => setOpenRejected(false)}
        >
          <Typography style={{color: '#525252', marginBottom: '8px'}}>
            Lý do không duyệt mục tiêu {' '}
            <span style={{color: '#FF3141'}}>*</span>
          </Typography>
          <Controller
            control={control}
            name="messageReason"
            rules={{
              required: 'Vui lòng điền lý do'
            }}
            render={({field: {onChange, value}, fieldState}) => {
              return (
                <div>
                  <Input
                    type="textarea"
                    rows={4}
                    value={value}
                    onChange={onChange}
                    placeholder="Nhập lý do"
                  />
                  {fieldState?.error?.message && <ErrorMessage error={fieldState?.error?.message}/>}
                </div>
              )
            }}
          />
        </Modal>
      </form>

      {/*----------------- ADMIN HOÀN DUYỆT MỤC TIÊU ---------------------*/}
      <Modal
        open={openUndo}
        modalType='confirm'
        title="Hoàn duyệt mục tiêu"
        okText="Đồng ý"
        cancelText="Hủy"
        onOk={() => handleAdminActions(ChildrenAimStatusType.REGISTER_GOAL, 'Hoàn duyệt mục tiêu thành công')}
        onCancel={() => setOpenUndo(false)}
      >
        <Typography
          style={{
            textAlign: 'center'
          }}
        >
          Xác nhận hoàn duyệt mục tiêu này?
        </Typography>
      </Modal>

      {/*----------------- ADMIN DUYỆT KẾT QUẢ ---------------------*/}
      <Modal
        open={openApprovedResult}
        modalType='confirm'
        title="Duyệt kết quả"
        okText="Đồng ý"
        cancelText="Hủy"
        onOk={() => handleApprovedRejectedGoalResult(ChildrenAimStatusType.APPROVED_RESULT, 'Duyệt kết quả mục tiêu thành công')}
        onCancel={() => setOpenApprovedResult(false)}
      >
        <Typography
          style={{
            textAlign: 'center'
          }}
        >
          Xác nhận duyệt kết quả mục tiêu này?
        </Typography>
      </Modal>

      {/*----------------- ADMIN DUYỆT KẾT QUẢ ---------------------*/}
      <Modal
        open={openRejectedResult}
        modalType='confirm'
        title="Không duyệt kết quả"
        okText="Đồng ý"
        cancelText="Hủy"
        onOk={() => handleApprovedRejectedGoalResult(ChildrenAimStatusType.REJECTED_RESULT, 'Từ chối kết quả mục tiêu thành công')}
        onCancel={() => setOpenRejectedResult(false)}
      >
        <Typography
          style={{
            textAlign: 'center'
          }}
        >
          Xác nhận không duyệt kết quả mục tiêu này?
        </Typography>
      </Modal>
    </DashboardLayout>
  )
}

export default ManagedByMeDetail;
