import Modal from "src/components/Modal";
import {Controller, FormProvider, useForm} from "react-hook-form";
import React, {useMemo, useState} from "react";
import Select from "src/components/Select";
import ErrorMessage from "src/components/ErrorMessage";
import {Typography} from "antd";
import {useAppDispatch} from "src/redux/hooks";
import {handleAddUserToGroup, handleGetAllUser} from "src/services/permissions.services";
import {DropdownSelectProps} from "src/utils/types";
import {toast} from "react-toastify";

interface Props {
  open: boolean
  closeCb?: () => void;
  successCb?: () => void;
  errorCb?: () => void;
  selectedRole?: any;
  roleId?: string
}

const defaultValues = {
  users: []
}

const allUserInitial = {
  data: [],
  pagination: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    from: 0,
    to: 0
  }
}

const AddEmployee = ({ open, closeCb, selectedRole, successCb, errorCb, roleId }: Props) => {
  const dispatch = useAppDispatch();
  const addEmployeeMethods = useForm({
    defaultValues
  });

  const [page, setPage]  =useState(2);
  const [searchKey, setSearchKey]  =useState('');
  const [allUser, setAllUser] = useState<any>(allUserInitial)

  const addEmployee = (data: { users: DropdownSelectProps[] }) => {
    if (!roleId) return;
    dispatch(handleAddUserToGroup({
      roleId,
      users: data.users.map(item => item.value)
    }))
      .unwrap()
      .then(() => {
        successCb && successCb();
        addEmployeeMethods.reset(defaultValues)
        toast.success("Thêm nhân viên thành công", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch(err => {
        addEmployeeMethods.reset(defaultValues)
        const message = err?.response?.data?.message;
        toast.error(message || 'Đã có lỗi xảy ra. Vui lòng thử lại sau', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        errorCb && errorCb();
      })
  }

  const userOptions = useMemo(() => {
    return allUser.data.map((item: any) => ({
      label: item.name,
      value: item.id,
      image: item.avatar,
      helperText: item.company
    }))
  }, [allUser])

  const handleFocus = () => {
    dispatch(handleGetAllUser({ page: 1, per_page: 10 }))
      .unwrap()
      .then(res => {
        setAllUser(res.data)
      })
      .catch(() => {
        setAllUser([])
      });
  }

  if (!open) return null;

  return (
    <FormProvider {...addEmployeeMethods}>
      <form onSubmit={addEmployeeMethods.handleSubmit(addEmployee)}>
        <Modal
          open={open}
          title={selectedRole ? "Chỉnh sửa nhân viên" : "Thêm nhân viên"}
          okText="Lưu thông tin"
          cancelText="Hủy bỏ"
          onCancel={() => {
            closeCb && closeCb();
            addEmployeeMethods.reset(defaultValues)
          }}
          style={{
            minWidth: '800px'
          }}
        >
          <Typography style={{color: '#525252', marginBottom: '4px', marginTop: '12px'}}>
            Danh sách nhân viên {' '}
            <span style={{color: '#FF3141'}}>*</span>
          </Typography>
          <Controller
            control={addEmployeeMethods.control}
            name="users"
            rules={{
              required: 'Vui lòng chọn nhân viên'
            }}
            render={({field: {onChange, value}, fieldState}) => {
              return (
                <div>
                  <Select
                    mode="multiple"
                    style={{
                      width: '100%'
                    }}
                    placeholder="--- Chọn nhân viên ---"
                    options={userOptions}
                    value={value}
                    onSelect={(value) => onChange(value)}
                    onPopupScroll={(event) => {
                      if (allUser.pagination.last_page < page) return;
                      const target = event.target as HTMLElement;
                      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
                        dispatch(handleGetAllUser({ page, per_page: 10, search: searchKey }))
                          .unwrap()
                          .then(res => {
                            setAllUser({
                              data: [
                                ...allUser.data,
                                ...res.data.data
                              ],
                              pagination: res.data.pagination
                            })
                          })
                          .catch(() => {
                            setAllUser(allUserInitial)
                          });
                        setPage(page + 1)
                      }
                    }}
                    onFetchOptions={(searchKey) => {
                      dispatch(handleGetAllUser({ page: 1, per_page: 10, search: searchKey }))
                        .unwrap()
                        .then(res => {
                          setAllUser(res.data)
                        })
                        .catch(() => {
                          setAllUser(allUserInitial)
                        });
                      setSearchKey(searchKey);
                    }}
                    onBlur={() => {
                      setAllUser(allUserInitial)
                    }}
                    onFocus={() => handleFocus()}
                  />
                  {fieldState?.error?.message && <ErrorMessage error={fieldState?.error?.message}/>}
                </div>
              )
            }}
          />
        </Modal>
      </form>
    </FormProvider>
  )
}

export default AddEmployee
