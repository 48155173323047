import {Radio, Typography} from "antd";
import DatePicker from "src/components/DatePicker";
import Input from "src/components/Input";
import React, {useEffect} from "react";
import {Controller, useFormContext} from "react-hook-form";
import {PeriodStatus} from "src/utils/enums";
import ErrorMessage from "src/components/ErrorMessage";
import dayjs from "dayjs";
import {handleDateValidation} from "src/utils/functions";

const CreateNewPeriod = () => {
  const {control, watch, trigger,} = useFormContext();
  const fromDate = watch('fromDate');
  const toDate = watch('toDate');

  useEffect(() => {
    if (fromDate || toDate) {
      trigger('fromDate')
      trigger('toDate')
    }
  }, [fromDate, toDate])

  return (
    <div>
      <Typography style={{color: '#525252', marginBottom: '4px'}}>
        Đặt tên giai đoạn {' '}
        <span style={{color: '#FF3141'}}>*</span>
      </Typography>
      <Controller
        control={control}
        name="name"
        rules={{
          required: 'Vui lòng điền thông tin'
        }}
        render={({field: {onChange, value}, fieldState}) => {
          return (
            <div>
              <Input
                value={value}
                onChange={onChange}
                placeholder="Đặt tên giai đoạn"
              />
              {fieldState?.error?.message && <ErrorMessage error={fieldState?.error?.message}/>}
            </div>
          )
        }}
      />
      <Typography style={{color: '#525252', marginBottom: '4px', marginTop: '12px'}}>
        Chọn ngày bắt đầu {' '}
        <span style={{color: '#FF3141'}}>*</span>
      </Typography>
      <Controller
        control={control}
        name="fromDate"
        rules={{
          required: 'Vui lòng chọn ngày',
          validate: () => {
            const fromDateTimestamp = dayjs(fromDate).valueOf();
            const toDateTimestamp = dayjs(toDate).valueOf();
            return handleDateValidation(fromDateTimestamp, toDateTimestamp);
          }
        }}
        render={({field: {onChange, value}, fieldState}) => {
          return (
            <>
              <DatePicker
                value={value}
                placeholder="--- Chọn ngày ---"
                onChange={(value) => onChange(value)}
                style={{
                  width: '100%'
                }}
              />
              {fieldState?.error?.message && <ErrorMessage error={fieldState?.error?.message}/>}
            </>
          )
        }}
      />
      <Typography style={{color: '#525252', marginBottom: '4px', marginTop: '12px'}}>
        Chọn ngày kết thúc {' '}
        <span style={{color: '#FF3141'}}>*</span>
      </Typography>
      <Controller
        control={control}
        name="toDate"
        rules={{
          required: 'Vui lòng chọn ngày',
          validate: () => {
            const fromDateTimestamp = dayjs(fromDate).valueOf();
            const toDateTimestamp = dayjs(toDate).valueOf();
            return handleDateValidation(fromDateTimestamp, toDateTimestamp);
          }
        }}
        render={({field: {onChange, value}, fieldState}) => {
          return (
            <>
              <DatePicker
                value={value}
                placeholder="--- Chọn ngày ---"
                onChange={(value) => onChange(value)}
                style={{
                  width: '100%'
                }}
              />
              {fieldState?.error?.message && <ErrorMessage error={fieldState?.error?.message}/>}
            </>
          )
        }}
      />
      <Typography style={{color: '#525252', marginBottom: '4px', marginTop: '12px'}}>Trạng thái</Typography>
      <Controller
        control={control}
        name="status"
        rules={{
          required: 'Vui lòng chọn trạng thái'
        }}
        render={({field: {onChange, value}}) => {
          return (
            <Radio.Group onChange={(e) => onChange(e.target.value)} value={value}>
              <Radio value={PeriodStatus.ACTIVE}>Hoạt động</Radio>
              <Radio value={PeriodStatus.INACTIVE}>Ngừng hoạt động</Radio>
            </Radio.Group>
          )
        }}
      />
    </div>
  )
}

export default CreateNewPeriod;