import {Typography} from "antd";
import React, {useMemo} from "react";
import {Controller, useFormContext} from "react-hook-form";
import {FilterParams} from "src/hooks/useFilter";
import {useInfinityScroll} from "src/hooks/useInfinityScroll";
import {debounce} from "lodash";
import Select from "src/components/Select";
import {ApartmentListItem, CreatePeriodResponse, DropdownSelectProps} from "src/utils/types";
import {ChildrenAimStatusType, EmployeeTypeEnum, LeaderFilter, StatusGoalFilter} from "src/utils/enums";
import {handleGetListOrganization} from "src/services/permissions.services";
import {handleGetMyPeriodList} from "src/services/periods.services";

import './styles.scss'

interface Props {
  onFilterToQueryString: (params: FilterParams) => void;
  filter: FilterParams
}

const ReportFilter = ({onFilterToQueryString, filter}: Props) => {
  const {control} = useFormContext();

  const {data: organizationList, handleLoadMore} = useInfinityScroll<ApartmentListItem>({
    onFetchData: handleGetListOrganization,
    defaultFilter: {page: 1, per_page: 10, is_managed: true}
  })

  const {data: periodList, handleLoadMore: handleLoadMorePeriod} = useInfinityScroll<CreatePeriodResponse>({
    onFetchData: handleGetMyPeriodList,
    defaultFilter: {page: 1, per_page: 10, is_public: true}
  })

  async function updateQuery(query: FilterParams) {
    const debouncedSearch = debounce(async (query) => {
      onFilterToQueryString({...filter, ...query})
    }, 300);
    debouncedSearch(query);
  }

  const organizationListFilter = useMemo(() => {
    return organizationList.data.map(item => ({
      label: item.name_organization_path || '',
      value: item.id as string
    }))
  }, [organizationList.data]);

  const periodListFilter = useMemo(() => {
    return periodList.data.map(item => ({
      label: item.name || '',
      value: item.id as string
    }))
  }, [periodList.data]);

  return (
    <div className='filter-wrap'>
      <div className='filter-wrap-item'>
        <div className='filter-item'>
          <Typography style={{ flex: 1 }}>Phòng ban</Typography>
          <Controller
            control={control}
            name="organization"
            render={({field: {onChange, value}}) => {
              return (
                <Select
                  style={{
                    flex: 5
                  }}
                  placeholder="--- Chọn phòng ban ---"
                  options={organizationListFilter}
                  mode='multiple'
                  value={value}
                  onSelect={(value) => {
                    if (!value) {
                      updateQuery({organization: undefined})
                      return;
                    }
                    const _organization = (value as DropdownSelectProps[]).map((it: DropdownSelectProps) => it.value)
                    updateQuery({organization: _organization})
                    onChange(value)
                  }}
                  onPopupScroll={(event) => handleLoadMore(event)}
                />
              )
            }}
          />
        </div>
        <div className='filter-item'>
          <Typography style={{ flex: 1 }}>
            Chức vụ
          </Typography>

          <Controller
            control={control}
            name="employeeType"
            rules={{
              required: 'Vui lòng chọn quyền'
            }}
            render={({field: {onChange, value}}) => {
              return (
                <Select
                  style={{
                    flex: 5
                  }}
                  placeholder="--- Chọn điều kiện lọc ---"
                  options={LeaderFilter}
                  value={value}
                  onSelect={(value) => {
                    onChange((value as DropdownSelectProps).value)
                    if ((value as DropdownSelectProps).value === EmployeeTypeEnum.ALL) {
                      updateQuery({employeeType: undefined})
                      return;
                    } else {
                      updateQuery({employeeType: (value as DropdownSelectProps).value as EmployeeTypeEnum})
                      return;
                    }
                  }}
                />
              )
            }}
          />
        </div>
      </div>

      <div className='filter-wrap-item'>
        <div className='filter-item'>
          <Typography style={{ flex: 1 }}>Giai đoạn</Typography>
          <Controller
            control={control}
            name="phase"
            render={({field: {onChange, value}}) => {
              return (
                <Select
                  style={{
                    flex: 5
                  }}
                  placeholder="--- Chọn giai đoạn ---"
                  options={periodListFilter}
                  value={value}
                  onSelect={(value) => {
                    if (!value) {
                      onChange(undefined);
                      updateQuery({phase: undefined})
                      return;
                    }
                    const phaseId = (value as DropdownSelectProps)?.value
                    updateQuery({phase: phaseId})
                    onChange(value)
                  }}
                  onPopupScroll={(event) => handleLoadMorePeriod(event)}
                />
              )
            }}
          />
        </div>
        <div className='filter-item'>
          <Typography style={{ flex: 1 }}>Trạng thái</Typography>
          <Controller
            control={control}
            name="statusGoal"
            render={({field: {onChange, value}}) => {
              return (
                <Select
                  style={{
                    flex: 5
                  }}
                  placeholder="--- Chọn trạng thái ---"
                  options={StatusGoalFilter}
                  value={value}
                  mode='multiple'
                  onSelect={(value) => {
                    if (!value) {
                      updateQuery({statusGoal: undefined})
                      return;
                    }
                    const _statusGoal = (value as DropdownSelectProps[]).map((it: DropdownSelectProps) => it.value)
                    updateQuery({statusGoal: _statusGoal as ChildrenAimStatusType[]})
                    onChange(value)
                  }}
                />
              )
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default ReportFilter;
