import {Space, Tooltip, Typography} from "antd";
import Button from "src/components/Button";
import {ChildrenAim as ChildrenAimIcon, Plus} from "src/assets/icons";
import Table from "src/components/Table";
import React, {useState} from "react";
import {ColumnsType} from "antd/es/table/interface";
import {
  AimLevelEnums,
  ChildrenAimStatusType,
  LevelGoalEnum,
  ManageTypes, ManageTypesRoutes,
  SentResultActionType,
  StatusTrendEnum,
  UnitSelections
} from "src/utils/enums";
import Modal from "src/components/Modal";
import RoleActions from "../../RoleActions";
import CreateOrEditAimLevel3 from "src/components/Form/CreateOrEditAimLevel3";
import {useNavigate, useParams} from "react-router";
import {AimResponseType, CreateAimResponseType} from "src/utils/types";
import {handleDeleteAim, handleGetAimDetail} from "src/services/aims.services";
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import CreateEditAction from "src/components/Form/ActionLevel3/CreateEdit";
import {toast} from "react-toastify";
import useRole from "src/hooks/useRole";
import {ProgressItem} from "src/pages/AimDetail/AimList/AimListLevel3";
import dayjs from "dayjs";
import ActionTable from "src/pages/AimDetail/ActionItem/ActionTable";

interface Props {
  parentId?: string;
  childAimList?: AimResponseType[];
  manaTypeRoute: ManageTypesRoutes
}

const AimListLevel2 = ({parentId, childAimList, manaTypeRoute}: Props) => {
  const { isActionWithOwnerGoal } = useRole();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { aimLevel2Detail, aimLevel3Detail, aimDetail: aimLevel1Detail} = useAppSelector((state) => state.aim);
  const {actionDetail} = useAppSelector((state) => state.action);

  const {aimIdLevel2} = useParams();

  // mục tiêu cấp 3
  const [openCreateAimLevel3, setOpenCreateAimLevel3] = useState(false);
  const [openDeleteAimLevel3, setOpenDeleteAimLevel3] = useState(false);
  const [openCreateEditActionLevel3, setOpenCreateEditActionLevel3] = useState(false);

  const [selectedItem, setSelectedItem] = useState<string>('');

  const handleOpenDeleteAimLevel = (action: CreateAimResponseType) => {
    setOpenDeleteAimLevel3(true);
    setSelectedItem(action.id);
  }

  const handleOpenEditAimChild = (action: CreateAimResponseType) => {
    setSelectedItem(action.id);
    if (action.levelGoal === LevelGoalEnum.LEVEL_3) {
      setOpenCreateAimLevel3(true);
      dispatch(handleGetAimDetail({id: action.id, level: AimLevelEnums.LEVEL_3}));
    }
  }

  const handleAimDetail = (action: CreateAimResponseType) => {
    if (action.levelGoal === LevelGoalEnum.LEVEL_3) {
      navigate(`/${manaTypeRoute}/${parentId}/${action?.parent?.id}/${action.id}`)
    }
  }

  const handleFastCreate = (action: CreateAimResponseType) => {
    if (action.levelGoal === LevelGoalEnum.LEVEL_3) {
      setOpenCreateEditActionLevel3(true);
      dispatch(handleGetAimDetail({id: action.id, level: AimLevelEnums.LEVEL_3}));
    }
  }

  const columns: ColumnsType<CreateAimResponseType> = [
    {
      title: 'Mục tiêu con',
      dataIndex: 'name',
      key: 'name',
      width: 330,
      render: (_, action) => {
        const {name} = action;
        return (
          <Typography
            style={{cursor: 'pointer'}}
            onClick={() => handleAimDetail(action)}
          >
            {name}
          </Typography>
        );
      },
    },
    {
      title: 'Tỷ trọng',
      dataIndex: 'proportion',
      key: 'proportion',
      width: 120,
      render: (_, action) => {
        const {proportion} = action;
        return (
          <Typography>{Math.round(Number(proportion) * 100) || 0}%</Typography>
        );
      },
    },
    {
      title: 'Đơn vị tính',
      dataIndex: 'unit',
      key: 'unit',
      width: 150,
      render: (_, action) => {
        const unit = UnitSelections.find(item => item.value === action?.unit);
        if (unit?.label) {
          return <Typography>{unit?.label}</Typography>
        }
        return (
          <Tooltip
            placement="bottomRight"
            title="Đo lường theo kiểu hoàn thành/ không hoàn thành"
          >
            -------
          </Tooltip>
        );
      },
    },
    {
      title: 'Xu hướng',
      dataIndex: 'statusTrend',
      key: 'statusTrend',
      width: 150,
      render: (_, action) => {
        const {statusTrend, typeMeasure} = action;
        if (typeMeasure === SentResultActionType.NUMBER) {
          return (
            <Typography>{statusTrend === StatusTrendEnum.DOWN ? 'Giảm' : 'Tăng'}</Typography>
          )
        }

        return (
          <Tooltip
            placement="bottomRight"
            title="Đo lường theo kiểu hoàn thành/ không hoàn thành"
          >
            -------
          </Tooltip>
        )
      },
    },
    {
      title: 'Thời gian bắt đầu',
      dataIndex: 'fromDate',
      key: 'fromDate',
      width: 180,
      render: (_, action) => {
        const {fromDate} = action;
        return (
          <Typography>{dayjs(fromDate).format('DD/MM/YYYY')}</Typography>
        );
      },
    },
    {
      title: 'Thời gian kết thúc',
      dataIndex: 'toDate',
      key: 'toDate',
      width: 180,
      render: (_, action) => {
        const {toDate} = action;
        return (
          <Typography>{dayjs(toDate).format('DD/MM/YYYY')}</Typography>
        );
      },
    },
    {
      title: 'Tỷ lệ hoàn thành',
      dataIndex: 'result',
      key: 'result',
      render: (_, action) => {
        return (
          <ProgressItem data={action}/>
        );
      },
    },
    {
      title: 'Thao tác',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, action) => {
        if (!isActionWithOwnerGoal) return null;
        return (
          <RoleActions
            handleOpenDeleteAimLevel={handleOpenDeleteAimLevel}
            handleOpenEditAimChild={handleOpenEditAimChild}
            handleAimDetail={handleAimDetail}
            handleFastCreate={handleFastCreate}
            action={action}
          />
        )
      },
      width: 250
    },
  ];
  // xóa mục tiêu cấp 3
  const handleDeleteAimItem = () => {
    dispatch(handleDeleteAim(selectedItem)).unwrap()
      .then(() => {
        dispatch(handleGetAimDetail({id: parentId as string, level: AimLevelEnums.LEVEL_2}));
        toast.success("Xóa mục tiêu thành công", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        setSelectedItem('');
        setOpenDeleteAimLevel3(false);
      })
      .catch((err: any) => {
        const message = err?.response?.data?.message;
        toast.error(message || 'Đã có lỗi xảy ra. Vui lòng thử lại sau', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        setSelectedItem('');
        setOpenDeleteAimLevel3(false);
      })
  }

  return (
    <Space className="aim-information" direction="vertical">
      <Space style={{padding: 16, borderBottom: '1px solid #EEEEEE', width: '100%', justifyContent: 'space-between'}}>
        <Space>
          <ChildrenAimIcon/>
          <Typography style={{fontWeight: 700, fontSize: '16px', lineHeight: '24px', color: '#525252'}}>Mục tiêu
            con</Typography>
        </Space>

        {/*Chỉ được thêm mới muc tieu cap 3 khi mục tiêu cấp 1 chưa được duyệt.*/}
        {
          aimLevel1Detail?.statusGoal !== ChildrenAimStatusType.APPROVED_GOAL && (
            <Button
              onClick={() => {
                setOpenCreateAimLevel3(true);
              }}
              style={{padding: '3px 8px', backgroundColor: '#2560E5', display: 'flex', alignItems: 'center', gap: '8px'}}
            >
              <Plus/>
              <Typography.Text style={{color: '#fff'}}>Thêm mục tiêu</Typography.Text>
            </Button>
          )
        }
      </Space>

      <Space style={{padding: 16, width: '100%'}} direction="vertical">
        <Table
          loading={false}
          rowKey={(record) => {
            return record.id as string
          }}
          expandable={{
            expandedRowRender: (record: any) => {
              return (
                <Space style={{ gap: 8, width: '100%' }} direction='vertical'>
                  <ActionTable
                    childAimList={record?.childrenActions}
                    parentId={record.parent.id}
                    loading={false}
                    aimDetail={aimLevel2Detail}
                    manaType={ManageTypes.OWNER}
                    level={AimLevelEnums.LEVEL_2}
                  />
                </Space>
              )
            },
            rowExpandable: (record: any) => {
              return record.childrenActions && record.childrenActions.length > 0
            },
          }}
          data={childAimList || []}
          columns={columns}
          isHidePagination
        />
      </Space>

      {/*----------------- Tạo & chỉnh sửa mục tiêu con level 3 ---------------------*/}
      <CreateOrEditAimLevel3
        open={openCreateAimLevel3}
        selectedItem={selectedItem}
        parentId={aimIdLevel2}
        successCb={() => {
          dispatch(handleGetAimDetail({ id: aimIdLevel2 as string, level: AimLevelEnums.LEVEL_2 }));
          setOpenCreateAimLevel3(false);
          setSelectedItem('');
        }}
        closeCb={() => {
          setOpenCreateAimLevel3(false)
          setSelectedItem('')
        }}
        errorCb={() => {
          setSelectedItem('');
        }}
      />

      {/*Tạo nhanh/ Chỉnh sửa hành động cấp 3*/}
      <CreateEditAction
        data={actionDetail}
        parentId={aimLevel3Detail?.id}
        open={openCreateEditActionLevel3}
        selectedItem={selectedItem}
        errorCb={() => {
          setOpenCreateEditActionLevel3(false)
        }}
        successCb={() => {
          dispatch(handleGetAimDetail({ id: aimIdLevel2 as string, level: AimLevelEnums.LEVEL_2 }));
          setOpenCreateEditActionLevel3(false)
        }}
      />

      {/*----------------- Xóa mục tiêu con cấp 3 ---------------------*/}
      <Modal
        open={openDeleteAimLevel3}
        modalType='confirm'
        title="Xóa mục tiêu con"
        okText="Đồng ý"
        cancelText="Hủy"
        onOk={handleDeleteAimItem}
        onCancel={() => {
          setOpenDeleteAimLevel3(false);
          setSelectedItem('');
        }}
      >
        <Typography
          style={{
            textAlign: 'center'
          }}
        >
          Xác nhận xoá mục con tiêu này
        </Typography>
      </Modal>
    </Space>
  )
}
export default AimListLevel2
