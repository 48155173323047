import {Radio, Space, Typography} from "antd";
import Input from "src/components/Input";
import React from "react";
import {SentResultActionType} from "src/utils/enums";
import ErrorMessage from "src/components/ErrorMessage";
import {Controller, useFormContext} from "react-hook-form";
import {NUMBER_REGEX} from "src/utils/regex";

interface Props {
  resultType: SentResultActionType
}

const SentActionResultLevel3 = (props: Props) => {
  const {resultType} = props;
  const { control } = useFormContext();
  return (
    <div>
      {
        resultType === SentResultActionType.NUMBER && (
          <Space style={{width: '100%'}} direction="vertical">
            <Typography style={{color: '#525252'}}>
              Kết quả mục tiêu {' '}
              <span style={{color: '#FF3141'}}>*</span>
            </Typography>
            <Controller
              control={control}
              name="result"
              rules={{
                required: 'Vui lòng điền kết quả'
              }}
              render={({field: {onChange, value}, fieldState}) => {
                return (
                  <div>
                    <Input
                      value={value}
                      onChange={(value) => {
                        if (NUMBER_REGEX.test(value) || value === '') {
                          onChange(value);
                        }
                      }}
                      placeholder="Nhập kết quả"
                    />
                    {fieldState?.error?.message && <ErrorMessage error={fieldState?.error?.message}/>}
                  </div>
                )
              }}
            />
          </Space>
        )
      }

      {
        resultType === SentResultActionType.STATUS && (
          <Space style={{width: '100%'}} direction="vertical">
            <Typography style={{color: '#525252', marginBottom: '4px', marginTop: '12px'}}>Kết quả mục tiêu</Typography>

            <Controller
              control={control}
              name="resultComplete"
              rules={{
                required: 'Vui lòng chọn trạng thái'
              }}
              render={({field: {onChange, value}}) => {
                return (
                  <Radio.Group
                    style={{
                      display: 'flex',
                    }}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    value={value}
                  >
                    <Radio value={1}>Hoàn thành</Radio>
                    <Radio value={0}>Chưa hoàn thành</Radio>
                  </Radio.Group>
                )
              }}
            />
          </Space>
        )
      }
    </div>
  )
}

export default SentActionResultLevel3;
