import Form from './Form'
import Modal from "src/components/Modal";
import {FormProvider, useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {CreateAction, CreateAimResponseType} from "src/utils/types";
import {SentResultActionType, StatusTrendSelections, TypeMeasureSelections, UnitSelections} from "src/utils/enums";
import {handleCreateNewAction, handleEditAction} from "src/services/actions.services";
import {useAppDispatch} from "src/redux/hooks";
import {toast} from "react-toastify";
import dayjs from "dayjs";

const defaultValues: CreateAction = {
  name: '',
  measure: undefined,
  fromDate: '',
  toDate: '',
}

interface Props {
  parentId?: string;
  selectedItem?: string;
  open: boolean;
  data: CreateAimResponseType | undefined;
  successCb?: () => void;
  errorCb?: () => void;
}

const CreateEditAction = ({ parentId, selectedItem, open, successCb, errorCb, data }: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const methods = useForm({
    defaultValues
  });

  const handleSuccess = () => {
    setLoading(false);
    methods.reset(defaultValues);
    if (!selectedItem) {
      toast.success("Tạo hành động thành công", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    } else {
      toast.success("Chỉnh sửa hành động thành công", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
    successCb && successCb();
  }

  const handleError = (err: any) => {
    setLoading(false);
    const message = err?.response?.data?.message;
    methods.reset(defaultValues)
    toast.error(message || 'Đã có lỗi xảy ra. Vui lòng thử lại sau', {
      position: toast.POSITION.BOTTOM_RIGHT
    });
    errorCb && errorCb()
  }

  const createEditAction = (dataForm: CreateAction) => {
    setLoading(true);

    const typeMeasure = dataForm?.typeMeasure?.value;
    let formData: CreateAction = {
      name: dataForm?.name,
      fromDate: dayjs(dataForm.fromDate).format('YYYY-MM-DD'),
      toDate: dayjs(dataForm.toDate).format('YYYY-MM-DD'),
    }

    if (typeMeasure === SentResultActionType.NUMBER) {
      formData = {
        ...formData,
        measure: Number(dataForm.measure),
        statusTrend: dataForm?.statusTrend?.value,
        unit: dataForm?.unit?.value,
        typeMeasure,
        result: dataForm?.unit?.value === data?.unit ? data?.result : null,
      }
    }
    if (typeMeasure === SentResultActionType.STATUS) {
      formData = {
        ...formData,
        measure: 1,
        typeMeasure
      }
    }

    if (selectedItem) {
      dispatch(handleEditAction({...formData, id: selectedItem}))
        .unwrap()
        .then(handleSuccess)
        .catch(handleError)
    } else {
      dispatch(handleCreateNewAction({...formData, goal: parentId as string}))
        .unwrap()
        .then(handleSuccess)
        .catch(handleError)
    }
  }

  useEffect(() => {
    if (!selectedItem) {
      methods.reset(defaultValues)
      return
    }
    const unit = UnitSelections.find(item => item.value === data?.unit);
    const typeMeasure = TypeMeasureSelections.find(item => item.value === data?.typeMeasure);
    const statusTrend = StatusTrendSelections.find(item => item.value === data?.statusTrend);

    methods.reset({
      name: data?.name,
      typeMeasure,
      measure: typeMeasure?.value === SentResultActionType.NUMBER ? data?.measure : 0,
      statusTrend,
      unit,
      fromDate: dayjs(data?.fromDate),
      toDate: dayjs(data?.toDate),
    })
  }, [data, methods, selectedItem])

  if (!open) return null;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(createEditAction)}>
        <Modal
          open={open}
          loadingSubmitButton={loading}
          modalType='form'
          title={selectedItem ? "Chỉnh sửa hành động" : "Thêm hành động"}
          okText={selectedItem ? "Cập nhật" : "Tạo mới"}
          cancelText="Hủy"
          onCancel={() => {
            errorCb && errorCb()
          }}
          style={{
            minWidth: '800px'
          }}
        >
          <Form/>
        </Modal>
      </form>
    </FormProvider>
  )
};

export default CreateEditAction
