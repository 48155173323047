import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {configThemes} from "./themes";
import {ConfigProvider} from 'antd';
import locale from 'antd/locale/en_US';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import updateLocale from 'dayjs/plugin/updateLocale';
import {Provider} from "react-redux";

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekdaysMin: ["S", "M", "T", "W", "T", "F", "S"],
  monthsShort: [
    "Tháng 1", "Tháng 2", "Tháng 3", "Tháng 4", "Tháng 5", "Tháng 6",
    "Tháng 7", "Tháng 8", "Tháng 9", "Tháng 10", "Tháng 11", "Tháng 12"
  ],
});

import './index.scss';
import {store} from "src/store";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider theme={configThemes} locale={locale}>
        <App/>
      </ConfigProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
