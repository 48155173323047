import DashboardLayout from "src/layout/DashboardLayout";
import {ArrowBackV2} from "src/assets/icons";
import {Space, Typography} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import AimInformation from '../AimInformation'
import {AimLevelEnums, ChildrenAimStatusType, ManageTypes, ManageTypesRoutes} from "src/utils/enums";
import {useNavigate, useParams} from "react-router";
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import {handleEditAim, handleGetAimDetail, handleGetRefAimList} from "src/services/aims.services";
import AimListLevel1 from "src/pages/AimDetail/AimList/AimListLevel1";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import {toast} from "react-toastify";
import {handleGetListOrganization} from "src/services/permissions.services";

const AimDetailLevel1 = () => {
  const navigate = useNavigate();
  const {aimIdLevel1} = useParams();
  const dispatch = useAppDispatch();
  const {aimDetail} = useAppSelector((state) => state.aim);

  const [openRequestApproved, setOpenRequestApproved] = useState(false);
  const [openResultApproved, setOpenResultApproved] = useState(false);

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!aimIdLevel1) return;
    setLoading(true);
    dispatch(handleGetAimDetail({id: aimIdLevel1, level: AimLevelEnums.LEVEL_1}))
      .unwrap()
      .then(() => setLoading(false))
      .catch(() => {
        setLoading(false);
        toast.error('Đã có lỗi xảy ra. Vui lòng thử lại sau', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
  }, [aimIdLevel1]);

  const handleRequestApproved = (status: ChildrenAimStatusType) => {
    if (!aimIdLevel1) return;
    dispatch(handleEditAim({
      id: aimIdLevel1,
      ...(
        status === ChildrenAimStatusType.REGISTER_GOAL && {
          isApprovedAllGoal: "false",
          statusGoal: ChildrenAimStatusType.REGISTER_GOAL
        }
      ),
      ...(
        status === ChildrenAimStatusType.SEND_RESULT && {
          statusResult: ChildrenAimStatusType.SEND_RESULT
        }
      )
    })).unwrap()
      .then(() => {
        dispatch(handleGetAimDetail({id: aimIdLevel1 as string, level: AimLevelEnums.LEVEL_1}))
        if (status === ChildrenAimStatusType.REGISTER_GOAL) {
          setOpenRequestApproved(false)
          toast.success('Yêu cầu duyệt lại thành công', {
            position: toast.POSITION.BOTTOM_RIGHT
          });
        }
        if (status === ChildrenAimStatusType.SEND_RESULT) {
          setOpenResultApproved(false)
          toast.success('Yêu cầu gửi duyệt kết quả thành công', {
            position: toast.POSITION.BOTTOM_RIGHT
          });
        }
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        toast.error(message || 'Đã có lỗi xảy ra. Vui lòng thử lại sau', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        if (status === ChildrenAimStatusType.REGISTER_GOAL) {
          setOpenRequestApproved(false)
        }
        if (status === ChildrenAimStatusType.SEND_RESULT) {
          setOpenResultApproved(false)
        }
      })
  }

  // hiển thị nút duyệt mục tiêu
  const isDisplayApprovedAimButton = useMemo(() => {
    return !aimDetail?.statusResult && aimDetail?.statusGoal === ChildrenAimStatusType.REJECTED_GOAL
  }, [aimDetail?.statusGoal, aimDetail?.statusResult])

  // hiển thị nút duyệt kết quả
  const isDisplayApprovedResultButton = useMemo(() => {
    return (!aimDetail?.statusResult && aimDetail?.statusGoal === ChildrenAimStatusType.APPROVED_GOAL) ||
      (aimDetail?.statusResult === ChildrenAimStatusType.REJECTED_RESULT && aimDetail?.statusGoal === ChildrenAimStatusType.APPROVED_GOAL)
  }, [aimDetail?.statusGoal, aimDetail?.statusResult])

  useEffect(() => {
    dispatch(handleGetListOrganization())
      .unwrap()
      .then(res => {
        const organizationList = res.data;
        if (organizationList.data.length == 1) {
          dispatch(handleGetRefAimList({organization: organizationList.data[0].id}));
        } else {
          dispatch(handleGetRefAimList());
        }
      })
    ;
  }, [])

  return (
    <DashboardLayout
      header={
        <Space style={{gap: '24px'}}>
          <ArrowBackV2 style={{cursor: 'pointer'}} onClick={() => navigate('/aims')}/>
          <div>
            <Typography className="dashboard-title">Chi tiết mục tiêu cấp 1</Typography>
            <Typography
              style={{
                color: '#737373',
                fontSize: '12px'
              }}
            >
              Mục tiêu/ <span style={{color: '#525252', fontWeight: 600}}>{aimDetail?.name}</span>
            </Typography>
          </div>
        </Space>
      }
      actionButtons={[
        (isDisplayApprovedAimButton && (
          <Button
            type="primary"
            key='create-period'
            onClick={() => setOpenRequestApproved(true)}
          >
            Yêu cầu duyệt lại mục tiêu
          </Button>
        )),
        (isDisplayApprovedResultButton && (
          <Button
            type="primary"
            key='create-period'
            onClick={() => setOpenResultApproved(true)}
          >
            Yêu cầu xác nhận kết quả
          </Button>
        ))
      ]}
    >
      <AimInformation level={AimLevelEnums.LEVEL_1} aimDetail={aimDetail} loading={loading}/>
      <AimListLevel1
        parentId={aimDetail?.id}
        childAimList={aimDetail?.childrens || []}
        loading={loading}
        manaType={ManageTypes.OWNER}
        manaTypeRoute={ManageTypesRoutes.OWNER}
      />

      {/*----------------- Yêu cầu duyệt lại ---------------------*/}
      <Modal
        open={openRequestApproved}
        modalType='confirm'
        title="Yêu cầu duyệt lại"
        okText="Đồng ý"
        cancelText="Hủy"
        onOk={() => handleRequestApproved(ChildrenAimStatusType.REGISTER_GOAL)}
        onCancel={() => {
          setOpenRequestApproved(false)
        }}
      >
        <Typography
          style={{
            textAlign: 'center'
          }}
        >
          Bạn muốn gửi yêu cầu duyệt lại với mục tiêu này?
        </Typography>
      </Modal>

      {/*----------------- Yêu cầu duyệt kết quả ---------------------*/}
      <Modal
        open={openResultApproved}
        modalType='confirm'
        title="Yêu cầu xác nhận kết quả"
        okText="Đồng ý"
        cancelText="Hủy"
        onOk={() => handleRequestApproved(ChildrenAimStatusType.SEND_RESULT)}
        onCancel={() => {
          setOpenResultApproved(false)
        }}
      >
        <Typography
          style={{
            textAlign: 'center'
          }}
        >
          Bạn muốn gửi yêu cầu xác nhận kết quả với mục tiêu này?
        </Typography>
      </Modal>
    </DashboardLayout>
  )
}

export default AimDetailLevel1;
