import DashboardLayout from "src/layout/DashboardLayout";
import Table from "src/components/Table";
import React, {useEffect, useState} from "react";
import {ColumnsType} from "antd/es/table/interface";
import {Progress, Space, Tag, Typography} from "antd";
import Button from "src/components/Button";
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import {handleExportExcel, handleGetReportList} from "src/services/aims.services";
import {useFilter} from "src/hooks/useFilter";
import {useNavigate} from "react-router";
import {CreateAimResponseType} from "src/utils/types";
import {handleChildrenAimState, handleOrganizationSelected} from "src/utils/functions";
import useRole from "src/hooks/useRole";
import {ChildrenAimStatusType, EmployeeTypeEnum, LeaderFilter} from "src/utils/enums";
import {FormProvider, useForm} from "react-hook-form";
import ReportFilter from "src/pages/Report/ReportFilter";
import {ExcelIcon} from "src/assets/icons";
import Modal from "src/components/Modal";
import {toast} from "react-toastify";

const ReportPage = () => {
  const {isReadEmployeeGoal, isWatchAndApprovedLeaderGoal, isExportExcel} = useRole();
  const methodsFilter = useForm();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {reportList, loading} = useAppSelector((state) => state.aim);
  const {organizationList} = useAppSelector((state) => state.permission);
  const {periodList} = useAppSelector((state) => state.period);

  const [openExportExcel, setOpenExportExcel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {filter, onFilterToQueryString} = useFilter({
    onFetchData: (filter) => {
      dispatch(handleGetReportList(filter));
    },
  })

  const columns: ColumnsType<CreateAimResponseType> = [
    {
      title: 'Tên mục tiêu cấp 1',
      dataIndex: 'name',
      key: 'name',
      width: 400,
      render: (_, action) => {
        return (
          <Typography.Text style={{cursor: 'pointer'}} onClick={() => navigate(`/report/${action?.id}`)}>
            {action?.name || '-'}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Người tạo',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (_, action) => {
        return (
          <Space>
            <Typography>{action?.created_by?.name || '-'}</Typography>
            <span
              style={{
                backgroundColor: action?.isGoalLeader ? '#FF3141' : '#52c41a',
                fontSize: '12px',
                lineHeight: '20px',
                padding: '2px 6px',
                color: '#fff',
                borderRadius: '6px'
              }}
            >
              {action?.isGoalLeader ? 'Leader' : 'Nhân viên'}
            </span>
          </Space>
        );
      },
      width: 250,
    },
    {
      title: 'Phòng ban',
      dataIndex: 'organization',
      key: 'organization',
      render: (_, action) => {
        return (
          <div>{action?.organization?.name || '-'}</div>
        );
      },
      width: 225,
    },
    {
      title: 'Giai đoạn',
      dataIndex: 'phase',
      key: 'phase',
      width: 220,
      render: (_, action) => {
        const {phase} = action;
        return (
          <Typography>{phase?.name || '-'}</Typography>
        );
      },
    },
    {
      title: 'Tỷ lệ hoàn thành',
      dataIndex: 'proportion',
      key: 'proportion',
      width: 280,
      render: (_, action) => {
        return (
        <div style={{width: 200}}>
          <Progress
            percent={+((action?.result || 0) * 100).toFixed(2) }
            strokeColor="#00B578"
            style={{width: '100%', margin: 0, fontSize: 14}}
          />
        </div>
        );
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'statusGoal',
      key: 'statusGoal',
      width: 168,
      render: (_, {statusGoal, statusResult}) => {
        const status = handleChildrenAimState(statusGoal as ChildrenAimStatusType, statusResult as ChildrenAimStatusType);
        return (
          <Tag
            style={{
              color: status.textColor,
              backgroundColor: status.bgColor,
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '20px',
              padding: '2px 12px',
              border: 'none'
            }}
          >
            {status.value}
          </Tag>
        );
      },
    },
    {
      title: 'Số mục tiêu c2',
      dataIndex: 'statusGoal',
      key: 'statusGoal',
      width: 168,
      render: (_, action) => {
        return (
          <Typography>
            {action?.lv2GoalCount || 0}
          </Typography>
        );
      },
    },
    {
      title: 'Số mục tiêu c3',
      dataIndex: 'statusGoal',
      key: 'statusGoal',
      width: 168,
      render: (_, action) => {
        return (
          <Typography>
            {action?.lv3GoalCount || 0}
          </Typography>
        );
      },
    },
    {
      title: 'Số lượng hành động',
      dataIndex: 'statusGoal',
      key: 'statusGoal',
      width: 200,
      render: (_, action) => {
        return (
          <Typography>
            {action?.actionCount || 0}
          </Typography>
        );
      },
    }
  ];

  useEffect(() => {
    const _organizationList = filter.organization ? handleOrganizationSelected(filter.organization, organizationList) : undefined;
    const periodSelected = periodList.data.find(item => item.id === filter?.phase);
    const employeeType = LeaderFilter.find(item => item.value === filter?.employeeType) || {
      label: 'Tất cả',
      value: EmployeeTypeEnum.ALL
    };
    methodsFilter.setValue('employeeType', employeeType)
    methodsFilter.setValue('phase', periodSelected ? {
      label: periodSelected?.name as string,
      value: periodSelected?.id as string
    } : undefined)
    methodsFilter.setValue('organization', _organizationList)

  }, [filter.organization, filter.employeeType, organizationList, methodsFilter]);

  if (!isReadEmployeeGoal && !isWatchAndApprovedLeaderGoal) {
    return <></>;
  }

  const onExportExcel = () => {
    setIsLoading(true);
    dispatch(handleExportExcel({
      ...filter,
      excel_export: true
    }))
      .unwrap()
      .then(res => {
        setIsLoading(false);
        setOpenExportExcel(false);
        window.open(res?.file_name, '_blank')
        toast.success("Xuất excel thành công", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch(err => {
        setIsLoading(false);
        setOpenExportExcel(false);
        toast.error("Có lỗi xảy ra. Vui lòng thử lại sau.", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
  }

  return (
    <DashboardLayout
      header={
        <Typography.Text className="dashboard-title">Báo cáo KPI</Typography.Text>
      }
      actionButtons={[
        isExportExcel && (
          <Button
            key='export'
            style={{display: 'flex', alignItems: 'center', gap: '8px'}}
            onClick={() => setOpenExportExcel(true)}
            loading={isLoading}
          >
            <ExcelIcon/>
            Xuất file Excel
          </Button>
        )
      ]}
      filterBox={
        <FormProvider {...methodsFilter}>
          <form>
            <ReportFilter onFilterToQueryString={onFilterToQueryString} filter={filter}/>
          </form>
        </FormProvider>
      }
    >
      <Table
        loading={loading}
        columns={columns}
        data={reportList.data || []}
        total={reportList?.pagination?.total || 0}
        pagination={filter}
        handleItemPerPage={(value) => {
          onFilterToQueryString({...filter, per_page: Number(value.value)})
        }}
        handlePageNumber={(value) => {
          onFilterToQueryString({...filter, page: value})
        }}
      />

      <Modal
        open={openExportExcel}
        modalType='confirm'
        title="Xuất file excel"
        okText="Đồng ý"
        cancelText="Hủy"
        onOk={onExportExcel}
        onCancel={() => {
          setOpenExportExcel(false);
        }}
      >
        <Typography
          style={{
            textAlign: 'center'
          }}
        >
          Bạn có đồng ý xuất file không?
        </Typography>
      </Modal>
    </DashboardLayout>
  )
}

export default ReportPage
