import DashboardLayout from "src/layout/DashboardLayout";
import ManagerByMeFilter from "src/pages/ManagedByMe/ManagedByMeFilter";
import Table from "src/components/Table";
import React, {useEffect} from "react";
import {ColumnsType} from "antd/es/table/interface";
import {Space, Tag, Typography} from "antd";
import Button from "src/components/Button";
import {Detail} from "src/assets/icons";
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import {handleGetAimList} from "src/services/aims.services";
import {useFilter} from "src/hooks/useFilter";
import {useNavigate} from "react-router";
import {CreateAimResponseType} from "src/utils/types";
import {handleChildrenAimState, handleOrganizationSelected} from "src/utils/functions";
import useRole from "src/hooks/useRole";
import {ChildrenAimStatusType, EmployeeTypeEnum, LeaderFilter} from "src/utils/enums";
import {FormProvider, useForm} from "react-hook-form";
import {ProgressItem} from "src/pages/AimDetail/AimList/AimListLevel3";

const ManagedByMe = () => {
  const {isReadEmployeeGoal, isWatchAndApprovedLeaderGoal} = useRole();
  const methodsFilter = useForm();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {aimList, loading} = useAppSelector((state) => state.aim);
  const {organizationList} = useAppSelector((state) => state.permission);
  const {periodList} = useAppSelector((state) => state.period);

  const {filter, onFilterToQueryString} = useFilter({
    onFetchData: (filter) => {
      dispatch(handleGetAimList(filter));
    },
  })

  const columns: ColumnsType<CreateAimResponseType> = [
    {
      title: 'Tên mục tiêu',
      dataIndex: 'name',
      key: 'name',
      width: 400,
      render: (_, action) => {
        return (
          <Typography.Text style={{cursor: 'pointer'}} onClick={() => navigate(`/managed-by-me/${action?.id}`)}>
            {action?.name || '-'}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Người tạo',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (_, action) => {
        return (
          <Space>
            <Typography>{action?.created_by?.name || '-'}</Typography>
            <span
              style={{
                backgroundColor: action?.isGoalLeader ? '#FF3141' : '#52c41a',
                fontSize: '12px',
                lineHeight: '20px',
                padding: '2px 6px',
                color: '#fff',
                borderRadius: '6px'
              }}
            >
              {action?.isGoalLeader ? 'Leader' : 'Nhân viên'}
            </span>
          </Space>
        );
      },
      width: 250,
    },
    {
      title: 'Phòng ban',
      dataIndex: 'organization',
      key: 'organization',
      render: (_, action) => {
        return (
          <div>{action?.organization?.name || '-'}</div>
        );
      },
      width: 225,
    },
    {
      title: 'Giai đoạn',
      dataIndex: 'phase',
      key: 'phase',
      width: 220,
      render: (_, action) => {
        const {phase} = action;
        return (
          <Typography>{phase?.name || '-'}</Typography>
        );
      },
    },
    {
      title: 'Tỷ lệ hoàn thành',
      dataIndex: 'proportion',
      key: 'proportion',
      width: 150,
      render: (_, action) => {
        return (
          <ProgressItem data={action}/>
        );
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'statusGoal',
      key: 'statusGoal',
      width: 168,
      render: (_, {statusGoal, statusResult}) => {
        const status = handleChildrenAimState(statusGoal as ChildrenAimStatusType, statusResult as ChildrenAimStatusType);
        return (
          <Tag
            style={{
              color: status.textColor,
              backgroundColor: status.bgColor,
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '20px',
              padding: '2px 12px',
              border: 'none'
            }}
          >
            {status.value}
          </Tag>
        );
      },
    },
    {
      title: 'Thao tác',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, action) => {
        return (
          <Button
            onClick={() => navigate(`/managed-by-me/${action.id}`)}
            icon={<Detail/>}
            style={{
              padding: 0,
              border: 'none',
              boxShadow: 'none',
              width: '28px',
              height: '28px',
              marginRight: '8px'
            }}/>
        );
      },
      width: 128
    },
  ];

  useEffect(() => {
    const _organizationList = filter.organization ? handleOrganizationSelected(filter.organization, organizationList) : undefined;
    const periodSelected = periodList.data.find(item => item.id === filter?.phase);
    const employeeType = LeaderFilter.find(item => item.value === filter?.employeeType) || {
      label: 'Tất cả',
      value: EmployeeTypeEnum.ALL
    };
    methodsFilter.setValue('employeeType', employeeType)
    methodsFilter.setValue('phase', periodSelected ? {
      label: periodSelected?.name as string,
      value: periodSelected?.id as string
    } : undefined)
    methodsFilter.setValue('organization', _organizationList)

  }, [filter.organization, filter.employeeType, organizationList, methodsFilter]);

  if (!isReadEmployeeGoal && !isWatchAndApprovedLeaderGoal) {
    return <></>;
  }

  return (
    <DashboardLayout
      header={
        <Typography.Text className="dashboard-title">Mục tiêu quản lý bởi tôi</Typography.Text>
      }
      filterBox={
        <FormProvider {...methodsFilter}>
          <form>
            <ManagerByMeFilter onFilterToQueryString={onFilterToQueryString} filter={filter}/>
          </form>
        </FormProvider>
      }
    >
      <Table
        loading={loading}
        columns={columns}
        data={aimList.data || []}
        total={aimList?.pagination?.total || 0}
        pagination={filter}
        handleItemPerPage={(value) => {
          onFilterToQueryString({...filter, per_page: Number(value.value)})
        }}
        handlePageNumber={(value) => {
          onFilterToQueryString({...filter, page: value})
        }}
      />
    </DashboardLayout>
  )
}

export default ManagedByMe
