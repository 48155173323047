import React, {useState} from 'react';
import {ArrowDown} from "src/assets/icons";
import {Avatar, Checkbox, Select as AntdSelect, Space, Spin, Tag, Typography} from 'antd';
import type {DefaultOptionType, SelectProps} from 'antd/es/select';
import {DropdownSelectProps} from "src/utils/types";
import {debounce, isArray, isEmpty} from "lodash";
import type {CustomTagProps} from 'rc-select/lib/BaseSelect';
import './styles.scss';

const Option = AntdSelect.Option;

interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children' | 'value'> {
  onSearch?: (search: string) => void;
  placeholder?: React.ReactNode;
  options?: ValueType[];
  isLoading: boolean;
  className?: string;
  suffixIcon?: React.ReactNode;
  showSearch?: boolean;
  value?: ValueType | ValueType[] | null;
}

function DebounceSelect<
  ValueType extends { key?: string; label: React.ReactNode; value: string | number, image?: string, helperText?: string } = any,
>({
    onSearch,
    placeholder,
    options,
    isLoading,
    className,
    value,
    suffixIcon = <ArrowDown/>,
    showSearch = true,
    mode,
                           allowClear = true,
    ...props
  }: DebounceSelectProps<ValueType>) {

  const [open, setOpen] = useState(false);

  const tagRender = (props: CustomTagProps) => {
    const {label, closable, onClose} = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
      >
        {label}
      </Tag>
    );
  };

  return (
    <AntdSelect
      open={open}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      filterOption={(input, option) => {
        return (option?.label ?? '' as any).toLowerCase().includes(input.toLowerCase())
      }}
      options={options}
      tagRender={tagRender}
      popupMatchSelectWidth
      showSearch={showSearch}
      labelInValue
      autoClearSearchValue={false}
      onSearch={onSearch}
      suffixIcon={suffixIcon}
      // menuItemSelectedIcon={mode !== 'multiple' ? <Checked/> : null}
      notFoundContent={options?.length === 0 ? <Typography style={{ padding: 12, textAlign: 'center' }}>Không có dữ liệu</Typography> : null}
      placeholder={placeholder}
      className={`${className} dropdown-wrapper`}
      popupClassName="dropdown-list"
      maxTagCount='responsive'
      value={value}
      allowClear={allowClear}
      mode={mode}
      {...props}
    >
      {
        options?.map((opt: ValueType) => {
          const isSelected = isArray(value) && value?.findIndex(item => item.label === opt.label) !== -1
          if (mode === 'multiple') {
            return (
              <Option key={opt.value} className="dropdown-item" label={opt.label}>
                {mode === 'multiple' && <Checkbox checked={isSelected}/>}
                <Avatar size={44} src={opt.image} alt="avatar" >{opt.label}</Avatar>
                <Space direction="vertical" style={{ gap: 0 }}>
                  <span className="label">{opt.label}</span>
                  <span className="label" style={{ color: '#737373' }}>{opt?.helperText || ''}</span>
                </Space>
              </Option>
            )
          }

          return (
            <Option key={opt.value} className="dropdown-item" label={opt.label}>{opt.label}</Option>
          )
        })
      }
    </AntdSelect>
  );
}

type Options<T> = Array<T>

interface AutocompleteProps {
  placeholder?: React.ReactNode;
  mode?: 'multiple' | 'tags';
  style?: React.CSSProperties;
  options: Options<DropdownSelectProps & { image?: string, helperText?: string }>;
  onSelect: (options: DropdownSelectProps | DropdownSelectProps[] | undefined) => void;
  value?: DropdownSelectProps | DropdownSelectProps[] | null;
  onFetchOptions?: (searchKey: string) => void;
  isLoading?: boolean;
  className?: string;
  suffixIcon?: React.ReactNode;
  showSearch?: boolean;
  onPopupScroll?: React.UIEventHandler<HTMLElement>;
  onBlur?: () => void;
  onFocus?: () => void;
  allowClear?: boolean
}

const Select = (props: AutocompleteProps) => {
  const {
    placeholder,
    mode,
    options= [],
    value,
    onSelect,
    onFetchOptions,
    isLoading = false,
    className,
    ...rest
  } = props;

  const handleSelectedValue = (newValue: any, option: DefaultOptionType[] | DefaultOptionType) => {
    if (isEmpty(option)) onSelect(undefined);
    else {
      if (isArray(option)) {
        const _options = option.map((item: DefaultOptionType) => ({
          label: item.label as string,
          value: item.value as string
        }))
        onSelect(_options)
      } else {
        onSelect({
          value: option.value as string,
          label: option.label as string
        })
      }
    }
  }

  const debouncedSearch = debounce(async (searchKey) => {
    onFetchOptions && onFetchOptions(searchKey)
  }, 300);

  return (
    <DebounceSelect
      mode={mode}
      value={value}
      placeholder={placeholder}
      options={options}
      onChange={(newValue, option) => handleSelectedValue(newValue, option)}
      onSearch={(searchKey: string) => debouncedSearch(searchKey)}
      isLoading={isLoading}
      className={className}
      {...rest}
    />
  );
};

export default Select;
