import {Badge, Space, Tooltip, Typography} from "antd";
import Button from "src/components/Button";
import {ChildrenAim as ChildrenAimIcon, Plus} from "src/assets/icons";
import Table from "src/components/Table";
import React, {useCallback, useMemo, useState} from "react";
import {ColumnsType} from "antd/es/table/interface";
import {handleDisplayActionButton, handleLevelBadge} from "src/utils/functions";
import {
  AimLevelEnums,
  HavingChildrenEnum,
  LevelGoalEnum,
  ManageTypes, ManageTypesRoutes, SentResultActionType,
  StatusTrendEnum,
  UnitSelections
} from "src/utils/enums";
import Modal from "src/components/Modal";
import RoleActions from "../../RoleActions";
import {useNavigate} from "react-router";
import {useForm} from "react-hook-form";
import {AimResponseType, CreateAimResponseType, CreateAimType} from "src/utils/types";
import {handleDeleteAim, handleGetAimDetail} from "src/services/aims.services";
import dayjs from "dayjs";
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import CreateOrEditAimLevel3 from "src/components/Form/CreateOrEditAimLevel3";
import CreateEditAction from "src/components/Form/ActionLevel3/CreateEdit";
import CreateOrEditAimLevel2 from "src/components/Form/CreateOrEditAimLevel2";
import {toast} from 'react-toastify';
import {resetAimDetail} from "src/reducers/aim.slice";
import useRole from "src/hooks/useRole";
import {ProgressItem} from "src/pages/AimDetail/AimList/AimListLevel3";
import ActionTable from "src/pages/AimDetail/ActionItem/ActionTable";

interface Props {
  parentId?: string;
  childAimList?: AimResponseType[];
  loading: boolean;
  manaType: ManageTypes;
  manaTypeRoute: ManageTypesRoutes
}

const defaultValues: CreateAimType = {
  name: '',
  fromDate: undefined,
  toDate: undefined,
  parent: null,
  reference: null,
  user: '',
  proportion: 0
}

const AimListLevel1 = ({
                         parentId,
                         childAimList,
                         loading,
                         manaType = ManageTypes.OWNER,
                         manaTypeRoute
                       }: Props) => {
  const {isActionWithOwnerGoal} = useRole();
  const {aimDetail, aimLevel2Detail, aimLevel3Detail} = useAppSelector((state) => state.aim);
  const {actionDetail} = useAppSelector((state) => state.action);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // mục tiêu cấp 2
  const [openCreateEditAimLevel2, setOpenCreateEditAimLevel2] = useState(false);
  const [openDeleteAimLevel2, setOpenDeleteAimLevel2] = useState(false);

  // mục tiêu cấp 3
  const [openCreateEditAimLevel3, setOpenCreateEditAimLevel3] = useState(false);
  const [openCreateEditActionLevel3, setOpenCreateEditActionLevel3] = useState(false);

  const [selectedItem, setSelectedItem] = useState<CreateAimResponseType | undefined>();

  const aimLevel2Methods = useForm({
    defaultValues
  });

  const { isDisplayFastCreateButton} = handleDisplayActionButton(aimDetail);

  const handleOpenDeleteAimLevel = (action: CreateAimResponseType) => {
    setOpenDeleteAimLevel2(true)
    setSelectedItem(action);
  }

  // -------------------------------------------------------------------------------------------------

  const handleOpenEditAimChild = useCallback((action: CreateAimResponseType) => {
    setSelectedItem(action);
    if (action.levelGoal === LevelGoalEnum.LEVEL_2) {
      setOpenCreateEditAimLevel2(true);
      dispatch(handleGetAimDetail({id: action.id, level: AimLevelEnums.LEVEL_2}));
    }

    if (action.levelGoal === LevelGoalEnum.LEVEL_3) {
      setOpenCreateEditAimLevel3(true);
      dispatch(handleGetAimDetail({id: action.id, level: AimLevelEnums.LEVEL_3}));
    }
  }, [dispatch])

  const handleAimDetail = useCallback((action: CreateAimResponseType) => {
    if (action.levelGoal === LevelGoalEnum.LEVEL_2) {
      navigate(`/${manaTypeRoute}/${action?.parent?.id}/${action?.id}`)
    }
    if (action.levelGoal === LevelGoalEnum.LEVEL_3) {
      navigate(`/${manaTypeRoute}/${parentId}/${action?.parent?.id}/${action.id}`)
    }
  }, [navigate, parentId])

  const handleFastCreate = useCallback((action: CreateAimResponseType) => {
    setSelectedItem(undefined);
    if (action.levelGoal === LevelGoalEnum.LEVEL_2) {
      if (action.levelCalculated === HavingChildrenEnum.NO_HAVING_CHILDREN) {
        setOpenCreateEditActionLevel3(true);
        dispatch(handleGetAimDetail({id: action.id, level: AimLevelEnums.LEVEL_3}));
      }
      if (action.levelCalculated === HavingChildrenEnum.HAVING_CHILDREN) {
        setOpenCreateEditAimLevel3(true);
        dispatch(handleGetAimDetail({id: action.id, level: AimLevelEnums.LEVEL_2}));
      }
    }

    if (action.levelGoal === LevelGoalEnum.LEVEL_3) {
      setOpenCreateEditActionLevel3(true);
      dispatch(handleGetAimDetail({id: action.id, level: AimLevelEnums.LEVEL_3}));
    }
  }, [dispatch])

  const columns: ColumnsType<CreateAimResponseType> = useMemo(() => {
    const defaultColumns: ColumnsType<CreateAimResponseType> = [
      {
        title: 'Tên mục tiêu',
        dataIndex: 'name',
        key: 'name',
        width: 330,
        render: (_, action) => {
          const {name, levelGoal} = action;
          const badgeColor = handleLevelBadge(levelGoal);
          return (
            <Badge
              size='small'
              count={levelGoal}
              style={{
                backgroundColor: badgeColor,
                fontSize: '10px',
                left: '-12px',
                top: '-6px',
                insetInlineEnd: 'unset',
                transform: 'none',
              }}
            >
              <Typography.Paragraph
                ellipsis={{
                  rows: 2,
                  tooltip: true
                }}
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => handleAimDetail(action)}
              >
                {name}
              </Typography.Paragraph>
            </Badge>
          );
        },
      },
      {
        title: 'Tỷ trọng',
        dataIndex: 'proportion',
        key: 'proportion',
        width: 120,
        render: (_, action) => {
          const {proportion} = action;
          return (
            <Typography>{Math.round(Number(proportion) * 100) || 0}%</Typography>
          );
        },
      },
      {
        title: 'Đơn vị tính',
        dataIndex: 'unit',
        key: 'unit',
        width: 150,
        render: (_, action) => {
          const unit = UnitSelections.find(item => item.value === action?.unit);
          const {levelCalculated, levelGoal} = action;
          const isTrue = (levelCalculated === HavingChildrenEnum.NO_HAVING_CHILDREN && levelGoal === LevelGoalEnum.LEVEL_2) || (levelGoal === LevelGoalEnum.LEVEL_3)
          if (!unit?.label) {
            return (
              <Tooltip
                placement="bottomRight"
                title={isTrue ? "Đo lường theo kiểu hoàn thành/ không hoàn thành" : "Mục tiêu cấp 2 đo lường theo mục tiêu cấp 3"}
              >
                -------
              </Tooltip>
            )
          }
          return (
            <Typography>{unit?.label}</Typography>
          );
        },
      },
      {
        title: 'Xu hướng',
        dataIndex: 'statusTrend',
        key: 'statusTrend',
        width: 150,
        render: (_, action) => {
          const {statusTrend, levelCalculated, levelGoal, typeMeasure} = action;
          const isTrue = (levelCalculated === HavingChildrenEnum.NO_HAVING_CHILDREN && levelGoal === LevelGoalEnum.LEVEL_2) || (levelGoal === LevelGoalEnum.LEVEL_3)

          if (typeMeasure === SentResultActionType.NUMBER) {
            return (
              <Typography>{statusTrend === StatusTrendEnum.DOWN ? 'Giảm' : 'Tăng'}</Typography>
            )
          }
          return (
            <Tooltip
              placement="bottomRight"
              title={isTrue ? "Đo lường theo kiểu hoàn thành/ không hoàn thành" : "Mục tiêu cấp 2 đo lường theo mục tiêu cấp 3"}
            >
              -------
            </Tooltip>
          );
        },
      },
      {
        title: 'Thời gian bắt đầu',
        dataIndex: 'fromDate',
        key: 'fromDate',
        width: 180,
        render: (_, action) => {
          const {fromDate} = action;
          return (
            <Typography>{dayjs(fromDate).format('DD/MM/YYYY')}</Typography>
          );
        },
      },
      {
        title: 'Thời gian kết thúc',
        dataIndex: 'toDate',
        key: 'toDate',
        width: 180,
        render: (_, action) => {
          const {toDate} = action;
          return (
            <Typography>{dayjs(toDate).format('DD/MM/YYYY')}</Typography>
          );
        },
      },
      {
        title: 'Tỷ lệ hoàn thành',
        dataIndex: 'result',
        key: 'result',
        width: 250,
        render: (_, action) => {
          return (
            <ProgressItem data={action}/>
          );
        },
      },
    ];

    if (manaType === ManageTypes.OWNER) {
      return [
        ...defaultColumns,
        {
          title: 'Thao tác',
          dataIndex: 'actions',
          key: 'actions',
          render: (_, action) => {
            if (!isActionWithOwnerGoal) return null;
            return (
              <RoleActions
                handleOpenDeleteAimLevel={handleOpenDeleteAimLevel}
                handleOpenEditAimChild={handleOpenEditAimChild}
                handleAimDetail={handleAimDetail}
                handleFastCreate={handleFastCreate}
                action={action}
              />
            )
          },
          width: 280
        },
      ]
    }

    return defaultColumns
  }, [manaType, handleAimDetail, handleFastCreate, handleOpenEditAimChild, isActionWithOwnerGoal])

  // ------------------------------------------------ XÓA MỤC TIÊU CON CẤP 2 --------------------------------------------------------
  const handleDeleteAimLevel2Item = () => {
    if (!selectedItem) return;
    dispatch(handleDeleteAim(selectedItem.id)).unwrap()
      .then(() => {
        setSelectedItem(undefined);
        setOpenDeleteAimLevel2(false)
        dispatch(handleGetAimDetail({id: parentId as string, level: AimLevelEnums.LEVEL_1}))
        toast.success('Xóa mục tiêu thành công', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        toast.error(message || 'Đã có lỗi xảy ra. Vui lòng thử lại sau', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        setSelectedItem(undefined);
        setOpenDeleteAimLevel2(false)
      })
  }

  return (
    <Space className="aim-information" direction="vertical">
      <Space style={{padding: 16, borderBottom: '1px solid #EEEEEE', width: '100%', justifyContent: 'space-between'}}>
        <Space>
          <ChildrenAimIcon/>
          <Typography style={{fontWeight: 700, fontSize: '16px', lineHeight: '24px', color: '#525252'}}>Mục tiêu
            con</Typography>
        </Space>
        {
          manaType === ManageTypes.OWNER && isActionWithOwnerGoal && isDisplayFastCreateButton && (
            <Button
              onClick={() => {
                aimLevel2Methods.reset(defaultValues)
                setOpenCreateEditAimLevel2(true)
              }}
              style={{padding: '3px 8px', backgroundColor: '#2560E5', display: 'flex', alignItems: 'center', gap: '8px'}}
            >
              <Plus/>
              <Typography.Text style={{color: '#fff'}}>Thêm mục tiêu</Typography.Text>
            </Button>
          )
        }
      </Space>

      <Space style={{padding: 16, width: '100%'}} direction="vertical">
        <Table
          loading={loading}
          rowKey={(record) => {
            return record.id as string
          }}
          expandable={{
            expandedRowRender: (record: any) => {
              if (record?.levelCalculated === HavingChildrenEnum.NO_HAVING_CHILDREN) {
                return (
                  <ActionTable
                    childAimList={record?.childrenActions}
                    parentId={parentId}
                    aimDetail={record}
                    loading={false}
                    manaType={manaType}
                    level={AimLevelEnums.LEVEL_1}
                  />
                )
              } else
                return (
                  <Table
                    showHeader={false}
                    loading={false}
                    columns={columns}
                    data={record.childrens || []}
                    isHidePagination
                    rowKey={(record) => {
                      return record.id as string
                    }}
                    expandable={{
                      expandedRowRender: (record: any) => {
                        return (
                          <ActionTable
                            childAimList={record?.childrenActions}
                            parentId={parentId}
                            loading={false}
                            aimDetail={record}
                            manaType={manaType}
                            level={AimLevelEnums.LEVEL_1}
                          />
                        )
                      },
                      rowExpandable: (record: any) => {
                        return record.childrenActions && record.childrenActions.length > 0
                      },
                    }}
                  />
                )
            },
            rowExpandable: (record: any) => {
              if (record?.levelCalculated === HavingChildrenEnum.NO_HAVING_CHILDREN) {
                return record.childrenActions && record.childrenActions.length > 0
              } else
                return record.childrens && record.childrens.length > 0
            },
          }}
          data={childAimList || []}
          columns={columns}
          isHidePagination
        />
      </Space>

      {/*----------------- Xóa mục tiêu con ---------------------*/}
      <Modal
        open={openDeleteAimLevel2}
        modalType='confirm'
        title="Xóa mục tiêu con"
        okText="Đồng ý"
        cancelText="Hủy"
        onOk={handleDeleteAimLevel2Item}
        onCancel={() => {
          setOpenDeleteAimLevel2(false)
          setSelectedItem(undefined)
        }}
      >
        <Typography
          style={{
            textAlign: 'center'
          }}
        >
          Xác nhận xoá mục con tiêu này
        </Typography>
      </Modal>

      {/* -----------------Tạo nhanh/ Chỉnh sửa mục tiêu cấp 2 -----------------*/}
      <CreateOrEditAimLevel2
        open={openCreateEditAimLevel2}
        selectedItem={selectedItem}
        parentId={parentId}
        errorCb={() => {
          setSelectedItem(undefined)
          dispatch(resetAimDetail(AimLevelEnums.LEVEL_2))
          dispatch(resetAimDetail(AimLevelEnums.LEVEL_3))
        }}
        closeCb={() => {
          setOpenCreateEditAimLevel2(false);
          setSelectedItem(undefined);
          dispatch(resetAimDetail(AimLevelEnums.LEVEL_2))
          dispatch(resetAimDetail(AimLevelEnums.LEVEL_3))
        }}
        successCb={() => {
          setOpenCreateEditAimLevel2(false);
          setSelectedItem(undefined)
          dispatch(handleGetAimDetail({id: parentId as string, level: AimLevelEnums.LEVEL_1}))
        }}
      />

      {/*----------------- Tạo nhanh/ Chỉnh sửa mục tiêu cấp 3 -----------------*/}
      <CreateOrEditAimLevel3
        open={openCreateEditAimLevel3}
        parentId={aimLevel2Detail?.id}
        selectedItem={selectedItem?.id}
        errorCb={() => {
          setSelectedItem(undefined)
          dispatch(resetAimDetail(AimLevelEnums.LEVEL_2))
          dispatch(resetAimDetail(AimLevelEnums.LEVEL_3))
        }}
        closeCb={() => {
          setOpenCreateEditAimLevel3(false)
          setSelectedItem(undefined)
        }}
        successCb={() => {
          setOpenCreateEditAimLevel3(false)
          setSelectedItem(undefined)
          dispatch(handleGetAimDetail({id: parentId as string, level: AimLevelEnums.LEVEL_1}));
        }}
      />

      {/* ----------------- Tạo nhanh/ Chỉnh sửa hành động cấp 3 -----------------*/}
      <CreateEditAction
        data={actionDetail}
        parentId={aimLevel3Detail?.id}
        selectedItem={selectedItem?.id}
        open={openCreateEditActionLevel3}
        errorCb={() => {
          setOpenCreateEditActionLevel3(false)
          setSelectedItem(undefined)
          dispatch(resetAimDetail(AimLevelEnums.LEVEL_2))
          dispatch(resetAimDetail(AimLevelEnums.LEVEL_3))
        }}
        successCb={() => {
          setOpenCreateEditActionLevel3(false)
          setSelectedItem(undefined)
          dispatch(handleGetAimDetail({id: parentId as string, level: AimLevelEnums.LEVEL_1}))
        }}
      />
    </Space>
  )
}
export default React.memo(AimListLevel1)
