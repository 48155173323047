import React from 'react';
import {useLocation, useNavigate} from 'react-router';
import querystring from 'query-string';
import {debounce} from 'lodash';
import {ChildrenAimStatusType, EmployeeTypeEnum} from "src/utils/enums";

export interface FilterParams {
  page?: number;
  per_page?: number;
  // sort?: string[];
  // orders?: string[];
  search?: string;
  phase?: string;
  organization?: string[];
  employeeType?: EmployeeTypeEnum;
  statusGoal?: ChildrenAimStatusType[];
  // searchKeyTypes?: string[];
  user?: string;
  fromDate?: any;
  toDate?: any;
}

interface Props {
  onFetchData?: (filter: FilterParams) => void;
  defaultFilter?: FilterParams;
}

export const initialFilter = {
  page: 1,
  per_page: 10,
};

export const useFilter = ({
  onFetchData,
  defaultFilter = initialFilter,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [filter, setFilter] = React.useState<FilterParams>(defaultFilter);

  const filterFromQuery = (query: FilterParams) => {
    const newFilter = {
      ...query,
      page: query.page ? +query.page : filter.page,
      per_page: query.per_page ? +query.per_page : filter.per_page,
      search: query.search ? query.search : query.user,
      phase: query.phase ? query.phase : query.phase,
      user: query.user ? query.user : filter.user,
      organization: query.organization ? query.organization : undefined,
      employeeType: query.employeeType ? query.employeeType : undefined,
      // orders: query.orders ? query.orders : filter?.orders,
      // searchKeyTypes: query.searchKeyTypes ? query.searchKeyTypes : [],
    };
    // if (query.sort) {
    //   set(
    //     newFilter,
    //     'orders',
    //     isArray(query.orders) ? query.orders : [query.orders],
    //   );
    // }
    return newFilter;
  };

  React.useEffect(() => {
    const params = querystring.parse(location.search, {
      arrayFormat: 'bracket',
    });
    const newFilter: FilterParams = filterFromQuery(params);
    setFilter(newFilter);
    const handleFetchData = debounce(
      () => onFetchData?.call(null, newFilter),
      500,
    );
    handleFetchData();
    return () => handleFetchData.cancel();
  }, [location.search]);

  const onFilterToQueryString = (values: FilterParams): void => {
    navigate(
      {
        pathname: location.pathname,
        search: `?${querystring.stringify(
          {
            ...values,
            page: values.page && values.page > 0 ? values.page : 0,
          },
          { arrayFormat: 'bracket', skipNull: true, skipEmptyString: true },
        )}`,
      },
      { replace: true },
    );
  };

  return { filter, onFilterToQueryString };
};
