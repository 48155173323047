import {Typography} from "antd";
import {Controller, useFormContext} from "react-hook-form";
import Select from "src/components/Select";
import ErrorMessage from "src/components/ErrorMessage";
import React, {useMemo, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import {handleGetMyPeriodList} from "src/services/periods.services";

const Form = () => {
  const {control, watch,} = useFormContext();
  const dispatch = useAppDispatch();

  const {myPeriodList} = useAppSelector((state) => state.period);
  const {organizationList} = useAppSelector((state) => state.permission);

  const organization = watch('organization');

  const periodListFilter = useMemo(() => {
    return myPeriodList.data.map(item => ({
      label: item.name || '',
      value: item.id as string
    }))
  }, [myPeriodList.data]);

  const organizationOptions = useMemo(() => {
    return organizationList.data.map((item: any) => ({
      label: item.name_organization_path as string,
      value: item.id
    }))
  }, [organizationList]);

  useEffect(() => {
    if (organizationList.data.length <= 1) {
      return;
    } else {
      dispatch(handleGetMyPeriodList({ organization: organization?.value }));
    }
  }, [organization])

  return (
    <div>
      <Typography style={{color: '#525252', marginBottom: '4px', marginTop: '12px'}}>
        Phòng ban {' '}
        <span style={{color: '#FF3141'}}>*</span>
      </Typography>

      <Controller
        control={control}
        name="organization"
        rules={{
          required: 'Vui lòng chọn phòng ban'
        }}
        render={({field: {onChange, value}, fieldState}) => {
          return (
            <div>
              <Select
                style={{
                  width: '100%'
                }}
                placeholder="--- Chọn phòng ban ---"
                options={organizationOptions}
                value={value}
                onSelect={(value) => onChange(value)}
              />
              {fieldState?.error?.message && <ErrorMessage error={fieldState?.error?.message}/>}
            </div>
          )
        }}
      />

      <Typography style={{color: '#525252', marginBottom: '4px', marginTop: '12px'}}>
        Giai đoạn thực hiện {' '}
        <span style={{color: '#FF3141'}}>*</span>
      </Typography>

      <Controller
        control={control}
        name="phase"
        rules={{
          required: 'Vui lòng chọn giai đoạn'
        }}
        render={({field: {onChange, value}, fieldState}) => {
          return (
            <div>
              <Select
                style={{
                  width: '100%'
                }}
                placeholder="--- Chọn giai đoạn ---"
                options={periodListFilter}
                value={value}
                onSelect={(value) => {
                  if (!value) {
                    onChange(null);
                  } else onChange(value)
                }}
              />
              {fieldState?.error?.message && <ErrorMessage error={fieldState?.error?.message}/>}
            </div>
          )
        }}
      />
    </div>
  )
}

export default Form;