import Button from "src/components/Button";
import {Delete, Detail, Edit, FastCreate} from "src/assets/icons";
import React from "react";
import {CreateAimResponseType} from "src/utils/types";
import {handleDisplayActionButton} from "src/utils/functions";
import useRole from "src/hooks/useRole";
import {useAppSelector} from "src/redux/hooks";

interface Props {
  action: CreateAimResponseType
  handleAimDetail?: (action: CreateAimResponseType) => void
  handleOpenDeleteAimLevel?: (action: CreateAimResponseType) => void
  handleOpenEditAimChild?: (action: CreateAimResponseType) => void
  handleFastCreate?: (action: CreateAimResponseType) => void
}

const RoleActions = (props: Props) => {
  const {
    action,
    handleOpenDeleteAimLevel,
    handleOpenEditAimChild,
    handleFastCreate,
    handleAimDetail,
  } = props;

  const { isActionWithOwnerGoal } = useRole();
  const {aimDetail: aimDetailLevel1} = useAppSelector((state) => state.aim);

  const { isDisplayEditButton, isDisplayDeleteButton, isDisplayFastCreateButton } = handleDisplayActionButton(aimDetailLevel1);

  if (isActionWithOwnerGoal) {
    return (
      <div>
        {
          isDisplayFastCreateButton && (
            <Button
              onClick={() => handleFastCreate && handleFastCreate(action)}
              icon={<FastCreate/>}
              style={{
                padding: 0,
                border: 'none',
                boxShadow: 'none',
                width: '28px',
                height: '28px',
                marginRight: '8px'
              }}/>
          )
        }

        <Button
          onClick={() => handleAimDetail && handleAimDetail(action)}
          icon={<Detail/>}
          style={{
            padding: 0,
            border: 'none',
            boxShadow: 'none',
            width: '28px',
            height: '28px',
            marginRight: '8px'
          }}/>

        {
          isDisplayEditButton && (
            <Button
              onClick={() => handleOpenEditAimChild && handleOpenEditAimChild(action)}
              icon={<Edit/>}
              style={{
                padding: 0,
                border: 'none',
                boxShadow: 'none',
                width: '28px',
                height: '28px',
                marginRight: '8px'
              }}/>
          )
        }
        {
          isDisplayDeleteButton && (
            <Button
              onClick={() => handleOpenDeleteAimLevel && handleOpenDeleteAimLevel(action)}
              icon={<Delete/>}
              style={{padding: 0, border: 'none', boxShadow: 'none', width: '28px', height: '28px'}}
            />
          )
        }
      </div>
    )
  }
  return null;
}

export default RoleActions;
