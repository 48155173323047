import Modal from "src/components/Modal";
import {FormProvider, useForm} from "react-hook-form";
import Form from './Form'
import React, {useEffect, useState} from "react";
import {CreateAimResponseType, CreateAimType} from "src/utils/types";
import dayjs from "dayjs";
import {handleCreateNewAim, handleEditAim} from "src/services/aims.services";
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import {toast} from "react-toastify";

interface Props {
  selectedItem?: CreateAimResponseType;
  open: boolean;
  successCb?: () => void;
  errorCb?: () => void;
  closeCb?: () => void
}

const defaultValues: CreateAimType = {
  reference: null,
  user: '',
  phase: undefined
}

const CreateOrEditAimLevel1 = ({ selectedItem, open, successCb, errorCb, closeCb }: Props) => {
  const [loading, setLoading] = useState(false);
  const aimLevel1Methods = useForm({
    defaultValues
  });
  const dispatch = useAppDispatch();

  const {userInfo} = useAppSelector((state) => state.user);
  const {periodList} = useAppSelector((state) => state.period);

  const handleSuccess = () => {
    setLoading(false);
    aimLevel1Methods.reset(defaultValues);
    successCb && successCb();
    if (selectedItem) {
      toast.success("Chỉnh sửa mục tiêu thành công", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    } else {
      toast.success("Tạo mới mục tiêu thành công", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  }

  const handleError = (err: any) => {
    setLoading(false);
    const message = err?.response?.data?.message;
    aimLevel1Methods.reset(defaultValues);
    errorCb && errorCb();
    if (message === 'GOAL_IS_EXISTED') {
      toast.error('Mục tiêu đã tồn tại', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    } else {
      toast.error(message || 'Đã có lỗi xảy ra. Vui lòng thử lại sau', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  }

  const createOrEdit = (data: CreateAimType) => {
    setLoading(true);
    const periodSelected = periodList.data.find(item => item.id === data?.phase?.value);
    const formData = {
      ...data,
      user: userInfo?.id,
      fromDate: dayjs(periodSelected?.fromDate).format('YYYY-MM-DD'),
      toDate: dayjs(periodSelected?.toDate).format('YYYY-MM-DD'),
      phase: data?.phase?.value,
      organization: data?.organization?.value,
    }

    if (selectedItem) {
      dispatch(handleEditAim({
        ...formData,
        id: selectedItem?.id,
      }))
        .unwrap()
        .then(handleSuccess)
        .catch(handleError)
    } else
      dispatch(handleCreateNewAim(formData))
        .unwrap()
        .then(handleSuccess)
        .catch(handleError)
  }

  useEffect(() => {
    if (!selectedItem) return;
    aimLevel1Methods.reset({
      name: selectedItem.name,
      phase: {
        label: selectedItem?.phase?.name as string,
        value: selectedItem?.phase?.id as string
      },
      organization: {
        label: selectedItem?.organization?.name as string,
        value: selectedItem?.organization?.id as string
      }
    })
  }, [selectedItem])

  return (
    <FormProvider {...aimLevel1Methods}>
      <form onSubmit={aimLevel1Methods.handleSubmit(createOrEdit)}>
        <Modal
          open={open}
          loadingSubmitButton={loading}
          modalType='form'
          title={selectedItem ? 'Chỉnh sửa mục tiêu cấp 1' : 'Tạo mới mục tiêu cấp 1'}
          okText={selectedItem ? 'Cập nhật' : 'Tạo mới'}
          cancelText="Hủy"
          onCancel={() => {
            aimLevel1Methods.reset(defaultValues);
            closeCb && closeCb();
          }}
          style={{
            minWidth: '800px'
          }}
        >
          <Form/>
        </Modal>
      </form>
    </FormProvider>
  )
}

export default React.memo(CreateOrEditAimLevel1);
