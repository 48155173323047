import axios from 'axios';
import Cookies from "src/utils/cookies";
import * as process from "process";
import {jwtDecode} from "jwt-decode";

const baseURL = process.env.REACT_APP_API_URL_BASE;
const redirectHref = process.env.REACT_APP_LOGIN_PAGE as string;
const okrLink = process.env.REACT_APP_OKR_PAGE as string;

declare module 'axios' {
  export interface AxiosRequestConfig {
    throwAccessDenied?: boolean; // is true if you want to self handle access denied exception
  }
}

export const axiosPrivate = axios.create({
  baseURL,
  headers: {
    'Accept-Language': 'en-US',
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
});

let isRefreshToken = false;

const refreshToken = Cookies.getRefreshToken();

const _axiosPrivate = axios.create({
  baseURL,
  headers: {
    'Accept-Language': 'en-US',
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    "Authorization": "Bearer " + refreshToken
  },
});

const instance = axiosPrivate;
const _instance = _axiosPrivate;

function createAxiosClient() {
  instance.interceptors.request.use(
    (config) => {
      const access_token = Cookies.getToken();
      if (access_token) {
        config.headers['Authorization'] = `Bearer ${access_token}`
      }
      return config;
    },
    (error) => {
      return Promise.reject(error)
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      const originalConfig = error.config;
      if (error?.response?.status === 401 && !isRefreshToken) {
        try {
          isRefreshToken = true
          const result = await _instance.post(`/sso-api/api/token/refresh`);
          const {access_token} = result.data.data;
          originalConfig.headers = {
            ...originalConfig.headers,
            Authorization: `Bearer ${access_token}`,
          };

          if (access_token) {

            const times = jwtDecode(access_token).exp as number;
            Cookies.setToken(access_token, String(times + 24 * 60 * 60 * 1000))
          }

          if (refreshToken) {
            const times = jwtDecode(refreshToken).exp as number;
            Cookies.setRefreshToken(refreshToken, String(times + 24 * 60 * 60 * 1000))
          }

          return _instance(originalConfig)
        } catch (err) {
          Cookies.removeToken();
          Cookies.removeRefreshToken();
          window.location.href = `${redirectHref}/login?redirect_url=${okrLink}`
          return Promise.reject(err)
        } finally {
          isRefreshToken = false;
        }
      }
      return Promise.reject(error)
    }
  )
  return instance
}

const instanceAxios = createAxiosClient();

export default instanceAxios
