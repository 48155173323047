import React from "react";

interface Props {
  error: string;
}

const ErrorMessage = (props: Props) => {
  const {error} = props;
  return (
    <>
      { error && <p style={{ color: '#EF4444', fontSize: '12px', marginTop: '4px' }}>{error}</p> }
    </>
  )
}

export default ErrorMessage