import instanceAxios from "src/utils/axios";
import {createAsyncThunk} from '@reduxjs/toolkit'
import {CreatePeriodType} from "src/utils/types";
import {getPeriodList, getPeriodDetail, finishLoading, startLoading, getMyPeriodList} from "src/reducers/period.slice";
import {FilterParams} from "src/hooks/useFilter";

export const handleCreateNewPeriod = createAsyncThunk(
  'period/create-new-period',
  async (params: CreatePeriodType, thunkAPI) => {
    try {
      const response = await instanceAxios.post(`/kpi-api/api/phase`, params)
      return response.data
    } catch(err) {
      return  thunkAPI.rejectWithValue(err)
    }
  }
)

export const handleGetPeriodList = createAsyncThunk(
  'period/get-list',
  async (params: FilterParams | undefined, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading())
      const response = await instanceAxios.get(`/kpi-api/api/phase`, {params})
      thunkAPI.dispatch(getPeriodList(response.data))
      thunkAPI.dispatch(finishLoading())
      return response.data.data
    } catch(err) {
      thunkAPI.dispatch(finishLoading())
      return  thunkAPI.rejectWithValue(err)
    }
  }
)

// lấy ra các giai đoạn của mình (có filter theo organization)
export const handleGetMyPeriodList = createAsyncThunk(
  'period/get-my-list',
  async (params: FilterParams | undefined, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading())
      const response = await instanceAxios.get(`/kpi-api/api/phase/my-phases`, {params})
      thunkAPI.dispatch(getMyPeriodList(response.data))
      thunkAPI.dispatch(finishLoading())
      return response.data.data
    } catch(err) {
      thunkAPI.dispatch(finishLoading())
      return  thunkAPI.rejectWithValue(err)
    }
  }
)

export const handleDeletePeriod = createAsyncThunk(
  'period/delete-period-item',
  async (param: string, thunkAPI) => {
    try {
      const response = await instanceAxios.delete(`/kpi-api/api/phase/${param}`)
      return response.data.data
    } catch(err) {
      return  thunkAPI.rejectWithValue(err)
    }
  }
)

export const handleEditPeriod = createAsyncThunk(
  'period/edit-period-item',
  async (param: CreatePeriodType, thunkAPI) => {
    const { id } = param;
    delete param.id;
    try {
      const response = await instanceAxios.put(`/kpi-api/api/phase/${id}`, param)
      return response.data.data
    } catch(err) {
      return  thunkAPI.rejectWithValue(err)
    }
  }
)

export const handlePeriodDetail = createAsyncThunk(
  'period/period-detail',
  async (param: string, thunkAPI) => {
    try {
      const response = await instanceAxios.get(`/kpi-api/api/phase/${param}`)
      thunkAPI.dispatch(getPeriodDetail(response.data))
      return response.data.data
    } catch(err) {
      return  thunkAPI.rejectWithValue(err)
    }
  }
)