import {FormProvider, useForm} from "react-hook-form";
import DashboardLayout from "src/layout/DashboardLayout";
import Table from "src/components/Table";
import Modal from "src/components/Modal";
import React, {useEffect, useState} from "react";
import {ColumnsType} from "antd/es/table/interface";
import {Tag, Typography} from "antd";
import {handlePeriodState} from "src/utils/functions";
import {PeriodStatus} from "src/utils/enums";
import CreateNewPeriod from "src/components/Form/CreateNewPeriod";
import Button from "src/components/Button";
import {Delete, Edit} from "src/assets/icons";
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import {
  handleCreateNewPeriod,
  handleDeletePeriod,
  handleEditPeriod,
  handleGetPeriodList,
  handlePeriodDetail
} from "src/services/periods.services";
import {CreatePeriodResponse, CreatePeriodType} from "src/utils/types";
import dayjs from "dayjs";
import {useFilter} from "src/hooks/useFilter";
import {toast} from "react-toastify";
import useRole from "src/hooks/useRole";

const defaultValues: CreatePeriodType = {
  name: '',
  fromDate: '',
  toDate: '',
  status: PeriodStatus.ACTIVE
}

const PeriodList = () => {
  const { isActionWithPeriod } = useRole();
  const {periodList, loadingPeriod, periodDetail} = useAppSelector((state) => state.period);
  const dispatch = useAppDispatch();
  const { filter, onFilterToQueryString } = useFilter({
    onFetchData: (filter) => {
      dispatch(handleGetPeriodList(filter));
    },
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues
  });

  const methodsFilter = useForm();

  const [openCreatePeriod, setOpenCreatePeriod] = useState(false);
  const [openDeletePeriod, setOpenDeletePeriod] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState<string>('')

  const handleSelectedPeriodItem = (id: string) => {
    setOpenDeletePeriod(true);
    setSelectedDeleteItem(id)
  }

  const handleSelectedPeriodEdit = (id: string) => {
    setOpenCreatePeriod(true);
    setSelectedDeleteItem(id)
    dispatch(handlePeriodDetail(id))
  }

  const columns: ColumnsType<CreatePeriodResponse> = [
    {
      title: 'Giai đoạn',
      dataIndex: 'name',
      key: 'name',
      width: 700,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (_, {status}) => {
        const periodState = handlePeriodState(status as PeriodStatus);
        return (
          <Tag
            style={{
              color: periodState.textColor,
              backgroundColor: periodState.bgColor,
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '20px',
              padding: '2px 12px',
              border: 'none'
            }}
          >
            {periodState.value}
          </Tag>
        );
      },
      width: 288
    },
    {
      title: 'Ngày bắt đầu',
      dataIndex: 'fromDate',
      key: 'fromDate',
      width: 168,
      render: (_, date) => {
        return (
          <Typography>{dayjs(date.fromDate).format('DD/MM/YYYY')}</Typography>
        );
      },
    },
    {
      title: 'Ngày kết thúc',
      dataIndex: 'toDate',
      key: 'toDate',
      width: 168,
      render: (_, date) => {
        return (
          <Typography>{dayjs(date.toDate).format('DD/MM/YYYY')}</Typography>
        );
      },
    },
    {
      title: 'Thao tác',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, action) => {
        if (action?.isExisted || !isActionWithPeriod) return null;
        return (
          <div>
            <Button
              onClick={() => handleSelectedPeriodEdit(action.id as string)}
              icon={<Edit/>}
              style={{
                padding: 0,
                border: 'none',
                boxShadow: 'none',
                width: '28px',
                height: '28px',
                marginRight: '8px'
              }}
            />
            <Button
              onClick={() => handleSelectedPeriodItem(action.id as string)}
              icon={<Delete/>}
              style={{padding: 0, border: 'none', boxShadow: 'none', width: '28px', height: '28px'}}
            />
          </div>
        );
      },
      width: 300
    },
  ];

  const handleSuccess = () => {
    methods.reset(defaultValues)
    setSelectedDeleteItem('');
    dispatch(handleGetPeriodList(filter));
    setOpenCreatePeriod(false)
    if (selectedDeleteItem) {
      toast.success('Chỉnh sửa giai đoạn thành công', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    } else {
      toast.success('Tạo mới giai đoạn thành công', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  }

  const handleError = (err: any) => {
    const message = err?.response?.data?.message;
    setSelectedDeleteItem('');
    methods.reset(defaultValues);
    toast.error(message || 'Đã có lỗi xảy ra. Vui lòng thử lại sau', {
      position: toast.POSITION.BOTTOM_RIGHT
    });
    setOpenCreatePeriod(false)
  }

  // tạo mới / chỉnh sửa giai đoạn
  const createNewPeriod = (data: CreatePeriodType) => {
    if (selectedDeleteItem) {
      dispatch(handleEditPeriod({
        ...data,
        fromDate: dayjs(data.fromDate).format('YYYY-MM-DD'),
        toDate: dayjs(data.toDate).format('YYYY-MM-DD'),
        id: selectedDeleteItem
      }))
        .unwrap()
        .then(() => handleSuccess())
        .catch((err) => handleError(err))
    } else
      dispatch(handleCreateNewPeriod({
        ...data,
        fromDate: dayjs(data.fromDate).format('YYYY-MM-DD'),
        toDate: dayjs(data.toDate).format('YYYY-MM-DD'),
      }))
        .unwrap()
        .then(() => handleSuccess())
        .catch((err) => handleError(err))
  }

  // Xóa giai đoạn
  const handleDeletePeriodItem = () => {
    dispatch(handleDeletePeriod(selectedDeleteItem)).unwrap()
      .then(() => {
        setSelectedDeleteItem('');
        methods.reset(defaultValues);
        setOpenDeletePeriod(false)
        dispatch(handleGetPeriodList(filter));
        toast.success('Xóa giai đoạn thành công', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch((err: any) => {
        const message = err?.response?.data?.message;
        toast.error(message || 'Đã có lỗi xảy ra. Vui lòng thử lại sau', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        methods.reset(defaultValues);
        setSelectedDeleteItem('')
        setOpenDeletePeriod(false)
      })
  }

  useEffect(() => {
    if (!selectedDeleteItem || !periodDetail) return;
    methods.reset({
      name: periodDetail.name,
      fromDate: dayjs(periodDetail?.fromDate),
      toDate: dayjs(periodDetail?.toDate),
      status: periodDetail.status as PeriodStatus
    })
  }, [selectedDeleteItem, methods, periodDetail]);

  useEffect(() => {
    methodsFilter.setValue('search', filter.search)
  }, [filter]);

  if (!isActionWithPeriod) {
    // navigate('/aims');
    return <></>;
  }

  return (
    <DashboardLayout
      header={
        <Typography.Text className="dashboard-title">Danh sách giai đoạn</Typography.Text>
      }
      actionButtons={[
        isActionWithPeriod && (
          <Button
            type="primary"
            key='create-period'
            onClick={() => setOpenCreatePeriod(true)}
          >
            Tạo giai đoạn
          </Button>
        )
      ]}
    >
      <Table
        loading={loadingPeriod}
        data={periodList.data || []}
        total={periodList.pagination.total}
        columns={columns}
        pagination={filter}
        handleItemPerPage={(value) => {
          onFilterToQueryString({ ...filter, per_page: Number(value.value) })
        }}
        handlePageNumber={(value) => {
          onFilterToQueryString({ ...filter, page: value })
        }}
      />

      {/*-------------------------------------- Tạo mới giai đoạn   --------------------------------------*/}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(createNewPeriod)}>
          <Modal
            open={openCreatePeriod}
            modalType='form'
            title={selectedDeleteItem ? "Chỉnh sửa giai đoạn" : "Tạo mới giai đoạn"}
            okText={selectedDeleteItem ? "Cập nhật" : "Tạo mới giai đoạn"}
            cancelText="Hủy"
            onCancel={() => {
              setSelectedDeleteItem('');
              setOpenCreatePeriod(false);
              methods.reset(defaultValues)
            }}
          >
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(createNewPeriod)}>
                <CreateNewPeriod/>
              </form>
            </FormProvider>
          </Modal>
        </form>
      </FormProvider>
      {/*-------------------------------------- Tạo mới giai đoạn   --------------------------------------*/}

      <Modal
        open={openDeletePeriod}
        modalType='confirm'
        title="Xóa giai đoạn"
        okText="Đồng ý"
        cancelText="Hủy"
        onOk={handleDeletePeriodItem}
        onCancel={() => {
          setSelectedDeleteItem('');
          setOpenDeletePeriod(false);
        }}
      >
        <Typography
          style={{
            textAlign: 'center'
          }}
        >
          Sau khi xác nhận xóa bạn sẽ không thể hoàn tác
        </Typography>
      </Modal>
    </DashboardLayout>
  )
}

export default React.memo(PeriodList)